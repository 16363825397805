import { createSlice } from '@reduxjs/toolkit';

const JobReducer = createSlice({
    name: 'JobReducer',

    initialState: {
        jobs: null,
        isDetails: false,
        setJobSearchParams: {}
    },
  
    reducers: {
        setJobs: (state, action) => {
            state.jobs = action.payload
        },
        setIsDetails: (state, action) => {
            state.isDetails = action.payload
        },
        setJobSearchParams: (state, action) => {
            const currentState = state.job_search;

            const updateData = {
                ...action.payload,
            };
            return {
                ...state,
                job_search: {
                    ...currentState, 
                    ...updateData,   
                },
            };
        },
    }
})

export const JobActions = JobReducer.actions

export default JobReducer.reducer