import React, { Component } from "react";
import SearchImg from "../images/job-type/home-help/Analytical-Outline800.webp";
import PointerImg from "../images/job-type/home-help/IT-Support-Outline800.webp";
import FileImg from "../images/job-type/home-help/Recruitment800.webp";
import CommunicationImg from "../images/job-type/home-help/Communication-Outline.webp";
import HandShakeImg from "../images/job-type/home-help/ClientFacingOutline.webp";
import TicketImg from "../images/job-type/home-help/Consulting-outline.webp";
class HelpSection extends Component {
    render() {
        return (
            <div className="gradbay_can_help ">
                <h2 className="section_heading">How GradBay can help</h2>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                        <div className="row">
                            <div className="col-lg-6">
                            <div className="can_help_inner">
                                <div>
                                <figure>
                                    <img src={FileImg} alt="Advertise for free on a dedicated" />
                                </figure>
                                <p>Advertise for free on a dedicated Gen Z platform.</p>
                                </div>
                                <div>
                                <figure>
                                    <img src={PointerImg} alt="Advertise for free on a dedicated" />
                                </figure>
                                <p>Post as many jobs as you like.</p>
                                </div>
                                <div>
                                <figure>
                                    <img src={TicketImg} alt="Advertise for free on a dedicated" />
                                </figure>
                                <p>Instantly match with talented candidates.</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="can_help_inner">
                                <div>
                                <figure>
                                    <img src={CommunicationImg} alt="Advertise for free on a dedicated" />
                                </figure>
                                <p>Connect with 40,000 active Gen Z candidates every month.</p>
                                </div>
                                <div>
                                <figure>
                                    <img src={HandShakeImg} alt="Advertise for free on a dedicated" />
                                </figure>
                                <p>Screen condidate video profiles and engage via instant chat messaging.</p>
                                </div>
                                <div>
                                <figure>
                                    <img src={SearchImg} alt="Advertise for free on a dedicated" />
                                </figure>
                                <p>Identify diverse talent using search tools.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>    
            </div>
        );
    }
}

export default HelpSection;
