import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import { isIOS, isMobile } from "react-device-detect";
import Loadable from "react-loadable";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import Footer from "./components/Footer.js";
import Loader from "./components/AppLoader.js";
import Navbar from "./components/Navbar.js";
import utilities from "./components/Utilities";
import Home from "./Home.js";
// import Redirecter from './Redirecter.js';
import Server from "./Server";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "v2/Store";
import { history } from "v2/Utilities";
import ThemeProvider from "v2/Theme";
import Cookies from "js-cookie";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
const ApplyGovKickStarterScheme = Loadable({ loader: () => import ('./ApplyGovKickStarterScheme.js'), loading: Loader });

// const new_routes = ['login', 'signup', 'restore', 'apply-kickstart-scheme', 'advance-search', 'advance-search-graduates', 'cv-search', 'jobs', 'graduates']

const new_routes = ["apply-kickstart-scheme"];

const Login = Loadable({
  loader: () => import("v2/pages/auth/Login"),
  loading: Loader,
});
const Signup = Loadable({
  loader: () => import("v2/pages/auth/Signup"),
  loading: Loader,
});
const SignupPostJobFree = Loadable({
  loader: () => import("v2/pages/auth/SignupPostJobFree"),
  loading: Loader,
});
const ForgotPassword = Loadable({
  loader: () => import("v2/pages/auth/ForgotPassword"),
  loading: Loader,
});
const ResetPassword = Loadable({
  loader: () => import("v2/pages/auth/ResetPassword"),
  loading: Loader,
});
const CompanyProfileCompletion = Loadable({
  loader: () => import("v2/pages/auth/CompanyProfileCompletion"),
  loading: Loader,
});
const AdvanceJobSearch = Loadable({
  loader: () => import("v2/pages/search/AdvanceJobSearch"),
  loading: Loader,
});
const AdvanceGradSearch = Loadable({
  loader: () => import("v2/pages/search/AdvanceGradSearch"),
  loading: Loader,
});
// const CVSearch = Loadable({ loader: () => import ('app/pages/search/CVSearch'), loading: Loader });
const GraduateList = Loadable({
  loader: () => import("v2/pages/jobs/GraduateList"),
  loading: Loader,
});
const JobList = Loadable({
  loader: () => import("v2/pages/jobs/JobList"),
  loading: Loader,
});

const ReadabilityChecker = Loadable({
  loader: () => import("v2/pages/ReadabilityChecker"),
  loading: Loader,
});

const SalaryPredictor = Loadable({
  loader: () => import("v2/pages/SalaryPredictor"),
  loading: Loader,
});

const HistoricalSalary = Loadable({
  loader: () => import("v2/pages/HistoricalSalary"),
  loading: Loader,
});

const CareerHub = Loadable({
  loader: () => import("v2/pages/CareerHub"),
  loading: Loader,
});

let Utilities = new utilities();
const LinkedInPage = Loadable({
  loader: () => import("./LinkedInPage"),
  loading: Loader,
});
const JobOnboarding = Loadable({
  loader: () => import("./JobOnboarding.js"),
  loading: Loader,
});
const JobView = Loadable({
  loader: () => import("./JobView.js"),
  loading: Loader,
});
const JobStatistics = Loadable({
  loader: () => import("./JobStatistics.js"),
  loading: Loader,
});
// const RestorePassword = Loadable({ loader: () => import ('./RestorePassword.js'), loading: Loader });
const BlogList = Loadable({
  loader: () => import("./BlogList.js"),
  loading: Loader,
});
const BlogDetails = Loadable({
  loader: () => import("./BlogDetails.js"),
  loading: Loader,
});
const CareerHubDetails = Loadable({
  loader: () => import("./CareerHubDetails.js"),
  loading: Loader,
});
const FAQ = Loadable({ loader: () => import("./FAQ.js"), loading: Loader });
const FAQVideos = Loadable({
  loader: () => import("./FAQVideos.js"),
  loading: Loader,
});
const BlogEdit = Loadable({
  loader: () => import("./BlogEdit.js"),
  loading: Loader,
});
const GraduateCV = Loadable({
  loader: () => import("./GraduateCV.js"),
  loading: Loader,
});
const GraduateDashboard = Loadable({
  loader: () => import("./GraduateDashboard.js"),
  loading: Loader,
});
const GraduateSettings = Loadable({
  loader: () => import("./GraduateSettings.js"),
  loading: Loader,
});
const GraduateOnboarding = Loadable({
  loader: () => import("./GraduateOnboarding.js"),
  loading: Loader,
});
const BusinessOnboarding = Loadable({
  loader: () => import("./BusinessOnboarding.js"),
  loading: Loader,
});
const BusinessDashboard = Loadable({
  loader: () => import("./BusinessDashboard.js"),
  loading: Loader,
});
const CompanySettings = Loadable({
  loader: () => import("./CompanySettings.js"),
  loading: Loader,
});
const Messages = Loadable({
  loader: () => import("./Messages.js"),
  loading: Loader,
});
const AdminDashboard = Loadable({
  loader: () => import("./AdminDashboard.js"),
  loading: Loader,
});
const AdminAgentDashboard = Loadable({
  loader: () => import("./AdminAgentDashboard.js"),
  loading: Loader,
});
const AdminSonicDashboard = Loadable({
  loader: () => import("./AdminSonicDashboard.js"),
  loading: Loader,
});
const AdminLoginAs = Loadable({
  loader: () => import("./components/AdminLoginAs.js"),
  loading: Loader,
});
const ContactUs = Loadable({
  loader: () => import("./ContactUs.js"),
  loading: Loader,
});
const WishList = Loadable({
  loader: () => import("./WishList.js"),
  loading: Loader,
});
const IndustryDetails = Loadable({
  loader: () => import("./IndustryDetails.js"),
  loading: Loader,
});
const About = Loadable({ loader: () => import("./About.js"), loading: Loader });
const VacancyDetails = Loadable({
  loader: () => import("./VacancyDetails.js"),
  loading: Loader,
});
const GraduateDetails = Loadable({
  loader: () => import("./GraduateDetails.js"),
  loading: Loader,
});
const JobSearch = Loadable({
  loader: () => import("./JobSearch.js"),
  loading: Loader,
});
const JobSearchNew = Loadable({
  loader: () => import("./JobSearchNew.js"),
  loading: Loader,
});
const GradSearchNew = Loadable({
  loader: () => import("./GradSearchNew.js"),
  loading: Loader,
});
const OutsideJobSearch = Loadable({
  loader: () => import("./OutsideJobSearch.js"),
  loading: Loader,
});
const GraduateSearch = Loadable({
  loader: () => import("./GraduateSearch.js"),
  loading: Loader,
});
const AdvanceGraduateSearch = Loadable({
  loader: () => import("./AdvanceSearchGraduate.js"),
  loading: Loader,
});
const CVSearch = Loadable({
  loader: () => import("./CVSearch.js"),
  loading: Loader,
});
const Static = Loadable({
  loader: () => import("./Static.js"),
  loading: Loader,
});
const Error = Loadable({
  loader: () => import("./components/Error.js"),
  loading: Loader,
});
// const Login = Loadable({ loader: () => import ('./Login.js'), loading: Loader });
const Invite = Loadable({
  loader: () => import("./Invite.js"),
  loading: Loader,
});
const PotNoodleMarketing = Loadable({
  loader: () => import("./PotNoodleMarketing.js"),
  loading: Loader,
});
const PepsiCo = Loadable({
  loader: () => import("./PepsiCo.js"),
  loading: Loader,
});
const KickStarter = Loadable({
  loader: () => import("./KickStarter.js"),
  loading: Loader,
});
const GovKickStarterScheme = Loadable({
  loader: () => import("./GovKickStarterScheme.js"),
  loading: Loader,
});

const ThankyouPage = Loadable({
  loader: () => import("./components/ThankyouPage.js"),
  loading: Loader,
});
const EmailNewsletter = Loadable({
  loader: () => import("./components/EmailNewsletter.js"),
  loading: Loader,
});
const EmailVerification = Loadable({
  loader: () => import("./components/EmailVerification.js"),
  loading: Loader,
});
const InterviewScheduler = Loadable({
  loader: () => import("./components/InterviewScheduler.js"),
  loading: Loader,
});
const SubscriptionManager = Loadable({
  loader: () => import("./SubscriptionManager.js"),
  loading: Loader,
});
const EmailSignup = Loadable({
  loader: () => import("./EmailSignup.js"),
  loading: Loader,
});
const URLShortner = Loadable({
  loader: () => import("./URLShortner.js"),
  loading: Loader,
});
const Pricing = Loadable({
  loader: () => import("./Pricing.js"),
  loading: Loader,
});

const GPTJobDescription = Loadable({
  loader: () => import("./GPTJobDescription"),
  loading: Loader,
});

const PricingNew = Loadable({
  loader: () => import("./v2/pages/PricingNew"),
  loading: Loader,
});
const Services = Loadable({
  loader: () => import("./Services.js"),
  loading: Loader,
});
const Directory = Loadable({
  loader: () => import("./Directory.js"),
  loading: Loader,
});
const MarketingSubscription = Loadable({
  loader: () => import("./components/MarketingSubscription.js"),
  loading: Loader,
});
const AdvanceSearch = Loadable({
  loader: () => import("./AdvanceSearch"),
  loading: Loader,
});
const Marketing = Loadable({
  loader: () => import("./Marketing"),
  loading: Loader,
});
// const Deloitte = Loadable({ loader: () => import ('./Deloitte.js'), loading: Loader });
const UpdateEmailVerification = Loadable({
  loader: () => import("./UpdateEmailVerification.js"),
  loading: Loader,
});
const AdminMarketingDashboard = Loadable({
  loader: () => import("./AdminMarketingDashboard.js"),
  loading: Loader,
});
const CompnayApplicationSettings = Loadable({
  loader: () => import("./CompnayApplicationSettings.js"),
  loading: Loader,
});
const QuickApplySignup = Loadable({
  loader: () => import("./QuickApplySignup.js"),
  loading: Loader,
});
const MySubscription = Loadable({
  loader: () => import("./MySubscription.js"),
  loading: Loader,
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") != null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const GraduateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        parseInt(Cookies.get("user_type")) === 4 || (localStorage.getItem("user") != null && JSON.parse(localStorage.getItem("user")).type === 4) ? 
        (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const BusinessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        parseInt(Cookies.get("user_type")) === 3 ||
        (localStorage.getItem("user") != null &&
          JSON.parse(localStorage.getItem("user")).type === 3) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const CustomAuthRoute = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem("user");
  let user_type = "";
  if (user != null) {
    user_type = JSON.parse(user).type;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        user != null ? (
          user_type == "3" ? (
            <Redirect to="/my-profile/business" />
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem("user");
  let user_type = "";
  if (user != null) {
    user_type = parseInt(JSON.parse(user).type);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        user !== null && user_type == 6 ? (
          <Redirect to="/admin-agent" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const AdminSonicRoute = ({ component: Component, ...rest }) => {
  let user = localStorage.getItem("user");
  let user_type = "";
  if (user != null) {
    user_type = parseInt(JSON.parse(user).type);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        user !== null && user_type == 2 ? (
          <Redirect to="/admin-sonic-job" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      ie: false,
      currentUrl: "/",
      pathname: "/",
    };
  }

  componentDidMount() {
    let ua = navigator.userAgent;
    let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    this.setState({ ie: is_ie });

    let currentPage = window.location.href;

    let user = localStorage.getItem("user");

    const isExpress = currentPage.split('/')

    if (isExpress[4] === 'express' || isExpress[4] === 'hotjob' || isExpress[4] === 'post') {
      var date = new Date();
      var minutes = 300;
      date.setTime(date.getTime() + minutes * 60 * 1000);
      Cookies.set("user_type", 4, { expires: date });
    }

    if (
      currentPage.indexOf("login") === -1 &&
      (user === null || user === undefined)
    ) {
      window.globalVars.previousPage = currentPage;
      localStorage.setItem("previousPage", JSON.stringify(currentPage));
    } else {
      localStorage.removeItem("previousPage");
    }

    setInterval(
      function() {
        let urlNow = window.location.href;
        if (urlNow !== this.state.currentUrl) {
          this.setState({
            currentUrl: urlNow,
            pathname: window.location.pathname.split("/")[1],
          });
          this.getLatestUser();
        }
      }.bind(this),
      200
    );

    this.getConfigs(true);

    setInterval(
      function() {
        this.getConfigs();
      }.bind(this),
      300000
    );
  }

  componentWillMount() {
    let server = new Server();
    let utilitiesGlobal = new utilities();

    window.globalVars = {
      server: server,
      utilities: utilitiesGlobal,
      bucket: "https://s3.eu-west-2.amazonaws.com/gradbay/",
      bucketBlogCoverDir: "blog/covers/",
      bucketBlogVideosDir: "blog/videos/",
      bucketCareerHubCoverDir: "careerhub/covers/",
      bucketGraduateProfilePictureDir: "graduate/profile-pictures/",
      bucketBusinessProfilePictureDir: "business/profile-pictures/",
      bucketMessageAttachments: "email-attachments/",
      bucketGraduateVideoInterviewDir: "graduate/video-interviews/",
      bucketBusinessJobPictureDir: "business/job-pictures/",
      bucketInterviewAttachments: "business/job-attachments/",
      bucketBusinessJobVideosDir: "business/job-videos/",
      bucketBusinessJobPhotosDir: "business/job-pictures/",
      pageTitleBefore: "",
      pageTitleAfter: " | GradBay",
      domain: "https://gradbay.com/",
      outsideLocationCodes: {
        "New York": "ny",
      },
      jobApplications: {},
      tagSuggestionGroups: [
        /* "Analytics & Consultancy" */ [
          "Data Analysis",
          "Business Analysis/Commercial Analysis",
          "Management Consultancy",
          "Strategy Consultancy",
          "Digital Analysis",
          "SEO Analysis",
          "PPC Analysis",
          "Programmatic Advertising",
          "Advertising Operations",
          "Research Analysis",
          "Market Research",
          "Financial Analysis",
          "Accounting",
          "Real Estate Analysis",
          "Underwriting",
        ],
        /* "Marketing" */ [
          "Digital Marketing",
          "Social Media",
          "Copywriting",
          "Community Management",
          "Branding",
          "Email Marketing",
          "Campaign Management",
          "Performance Marketing",
          "Affiliate Marketing",
          "Acquisition Marketing",
          "Retention Marketing",
          "Ecommerce",
          "Media Planning",
          "Editorial",
          "Product Marketing ",
          "Media Buying",
        ],
        /* "Project Management, Events & Logistics" */ [
          "Project Management",
          "Event Management",
          "Operations",
          "Supply Chain",
          "Logistics",
        ],
        /* "Account Management & Sales" */ [
          "Account Management",
          "Client Services",
          "Business Development",
          "Sales",
          "Sales Support",
          "Lead Generation",
          "Telesales",
          "Field Sales",
          "Field Marketing",
        ],
        /* ""Customer Service"" */ [
          "Customer Services",
          "Customer Success",
          "Front-of-House",
          "Receptionist",
          "On-Boarding",
          "Property Management",
          "Estate Agency",
        ],
        /* ""Business Support"" */ [
          "Administration",
          "Business Support",
          "Executive Assistant",
          "Personal Assistant",
          "Office Management",
          "Team Assistant",
        ],
        /* ""HR & Recruitment"" */ [
          "Human Resources",
          "Internal Recruitment",
          "Resourcer",
          "Recruitment Consultant",
          "Executive Search",
        ],
        /* ""Design"" */ [
          "Graphic Design",
          "Web Design",
          "Games Design",
          "UX Design",
          "UI Design",
          "Mobile Design",
          "Video Production",
          "Fashion Design",
          "Product Design",
        ],
        /* ""Other"" */ [
          "Merchandising",
          "Compliance",
          "Procurement",
          "Engineering",
        ],
        /* ""Digital Marketing"" */ [
          "SEO",
          "PPC",
          "Social Media",
          "Google AdWords",
          "Google Analytics",
          "CRM",
          "WordPress",
          "Hootsuite",
          "SurveyMonkey",
          "Copywriting",
          "Email Marketing",
          "Acquisition Marketing",
          "B2B Marketing",
          "B2C Marketing",
          "Facebook Ads",
          "Facebook Analytics",
          "LinkedIn Ads",
          "Marketing Cloud",
          "Retention Marketing",
          "Twitter Ads",
          "Twitter Analytics",
          "YouTube",
          "CMS",
          "Magento",
          "Mailchimp",
          "Magento",
        ],
        /* ""Data Analysis"" */ [
          "Alteryx",
          "Financial Modelling",
          "MATLAB",
          "Xero",
          "Sage",
          "QuickBooks",
          "LaTeX",
          "R",
        ],
        /* ""Data Analysis - Excel"" */ [
          "HLOOKUPs",
          "Macros",
          "Pivot Tables",
          "VLOOKUPs",
        ],
        /* ""Design"" */ [
          "Adobe Creative Suite (all)",
          "Adobe Illustrator",
          "Adobe InDesign",
          "Adobe Photoshop",
          "AutoCAD",
          "UI",
          "UX",
          "Wireframing",
          "SolidWorks",
          "Sketch",
          "Adobe XD",
          "Figma",
        ],
        /* ""Tehnical Support"" */ [
          "Microsoft Office",
          "Active Directory",
          "Windows Server",
          "Microsoft Azure",
          "Linux",
          "Cisco ",
          "AWS",
          "Google Cloud",
        ],
        /* ""Front-end Development"" */ [
          "CSS",
          "Dreamweaver",
          "HTML",
          "JavaScript",
          "React.js",
          "jQuery",
          "Angular",
          "Vue.js",
        ],
        /* ""Back-end Development"" */ [
          "C#",
          "C++",
          "Java",
          "JavaScript",
          "Python",
          "R",
          "Ruby-on-Rails",
          ".NET",
          "Django ",
          "PHP",
          "WordPress",
          "MongoDB",
          "PostgreSQL",
          "MySQL",
          "Drupal",
          "ASP.NET",
        ],
        /* ""Recruitment & Sales"" */ [
          "Bullhorn",
          "Cold calling",
          "Lead Generation",
          "LinkedIn",
          "Salesforce",
          "Networking",
        ],
        /* ""Research"" */ [
          "Market Segmentation",
          "SPSS",
          "Focus Groups",
          "Interviewing",
        ],
        /* ""Video Production"" */ [
          "Adobe After Effects",
          "Adobe Premiere Pro",
          "Final Cut Pro",
        ],
        /* ""Game Development"" */ ["Unity", "Unreal"],
        /* ""Mobile Development"" */ [
          "Swift",
          "Objective C",
          "Java",
          "IONIC",
          "Phonegap",
          "Cordova",
          "Capacitor",
          "React Native",
        ],
        /* ""Most Popular Programming "" */ [
          "Java",
          "Python",
          "C++",
          "C#",
          "Visual Basic .NET",
          "JavaScript",
          "PHP",
          "Objective-C",
          "SQL",
          "Ruby",
          "MATLAB",
          "Go",
          "Perl",
          "ASP.NET",
          "Rust",
          "Haskell",
        ],
      ],
      autoSuggestions: {
        GCSE_GRADES: [
          { text: "9", id: "9" },
          { text: "8", id: "8" },
          { text: "7", id: "7" },
          { text: "6", id: "6" },
          { text: "5", id: "5" },
          { text: "4", id: "4" },
          { text: "3", id: "3" },
          { text: "2", id: "2" },
          { text: "1", id: "1" },
        ],
        ALEVEL_GRADES: [
          { text: "A*", id: "A*" },
          { text: "A", id: "A" },
          { text: "B", id: "B" },
          { text: "C", id: "C" },
          { text: "D", id: "D" },
          { text: "E", id: "E" },
          { text: "U", id: "U" },
        ],
        IB_GRADES: [
          { text: "7", id: "7" },
          { text: "6", id: "6" },
          { text: "5", id: "5" },
          { text: "4", id: "4" },
          { text: "3", id: "3" },
          { text: "2", id: "2" },
          { text: "1", id: "1" },
        ],
        GRADES: [
          { text: "A+", id: "A+" },
          { text: "A", id: "A" },
          { text: "A-", id: "A-" },
          { text: "B+", id: "B+" },
          { text: "B", id: "B" },
          { text: "B-", id: "B-" },
          { text: "C+", id: "C+" },
          { text: "C", id: "C" },
          { text: "C-", id: "C-" },
          { text: "D+", id: "D+" },
          { text: "D", id: "D" },
          { text: "E/F", id: "E/F" },
        ],
        ALEVELS: [
          { text: "A-Level", id: "A-Level" },
          { text: "GCSE", id: "GCSE" },
          { text: "IB", id: "IB" },
        ],
        EDUCATION_TYPE: [
          { text: "School", id: "School" },
          { text: "College", id: "College" },
          { text: "University", id: "University" },
          { text: "Other", id: "Other" },
        ],
        FIRST_ATTEND_UNIVERSITY: [
          { text: "Yes", id: "Yes" },
          { text: "No", id: "No" },
        ],
        SOURCES: [
          { text: "Pot Noodle Campaign", id: "Pot Noodle Campaign" },
          { text: "Google", id: "Google" },
          { text: "Reed", id: "Reed" },
          { text: "Indeed", id: "Indeed" },
          { text: "Guardian", id: "Guardian" },
          { text: "Instagram", id: "Instagram" },
          { text: "Facebook", id: "Facebook" },
          { text: "Other", id: "Other" },
          { text: "Friend", id: "Friend" },
          { text: "GradBay Marketing", id: "GradBay Marketing" },
        ],
        POTNOODLESOURCES: [
          { text: "Callum Whiteley", id: "Callum Whiteley" },
          { text: "Denika Millington", id: "Denika Millington" },
          { text: "Sam Smith", id: "Sam Smith" },
          { text: "Victoria Jones", id: "Victoria Jones" },
          { text: "Aliyah Abbas", id: "Aliyah Abbas" },
          { text: "Rebecca Louise McKenna", id: "Rebecca Louise McKenna" },
          { text: "Charlie Quixley", id: "Charlie Quixley" },
          { text: "Cam Causer", id: "Cam Causer" },
          { text: "Ryan Chang", id: "Ryan Chang" },
          { text: "Kavan Nijjer", id: "Kavan Nijjer" },
          { text: "Fred Parker", id: "Fred Parker" },
          { text: "Lauren Owen", id: "Lauren Owen" },
          { text: "Olivia Drysdale", id: "Olivia Drysdale" },
          { text: "Jasmine Baragwanath", id: "Jasmine Baragwanath" },
          { text: "Nina Watson", id: "Nina Watson" },
          { text: "Daniel Kikbel", id: "Daniel Kikbel" },
          { text: "Charles Ryder", id: "Charles Ryder" },
          { text: "Sophie Evans", id: "Sophie Evans" },
          { text: "Sophie Tomkins", id: "Sophie Tomkins" },
          { text: "Molly Locke", id: "Molly Locke" },
          { text: "Jessica Phillips", id: "Jessica Phillips" },
          { text: "Tom Farmilo", id: "Tom Farmilo" },
          { text: "Chris Lillie", id: "Chris Lillie" },
          { text: "Aman Padan", id: "Aman Padan" },
          { text: "Yasmin Taylor", id: "Yasmin Taylor" },
          { text: "Abby Wynne", id: "Abby Wynne" },
          { text: "Jamie Kimber", id: "Jamie Kimber" },
          { text: "Nicole Whelan", id: "Nicole Whelan" },
          { text: "Boris Strumenliev", id: "Boris Strumenliev" },
          { text: "Millie Burge", id: "Millie Burge" },
          { text: "Aneeqa Siddiqui", id: "Aneeqa Siddiqui" },
          { text: "Angie Galdini", id: "Angie Galdini" },
          { text: "Belle Blackburn", id: "Belle Blackburn" },
          { text: "Meg Jenkinson", id: "Meg Jenkinson" },
          { text: "Shannon Seaton", id: "Shannon Seaton" },
          { text: "Eden Estrajch-Moffatt", id: "Eden Estrajch-Moffatt" },
          { text: "Sam Wash", id: "Sam Wash" },
          { text: "Ned Smith", id: "Ned Smith" },
          { text: "Sam Pougatch", id: "Sam Pougatch" },
          { text: "Emily Bacchus-Waterman", id: "Emily Bacchus-Waterman" },
          { text: "Arwen Lewis-Anthony", id: "Arwen Lewis-Anthony" },
          { text: "Edward Dickinson", id: "Edward Dickinson" },
          { text: "Maggie Woloski", id: "Maggie Woloski" },
          { text: "Grace Chambers", id: "Grace Chambers" },
          { text: "Fern Gable", id: "Fern Gable" },
          { text: "Bethany E Bracken", id: "Bethany E Bracken" },
          { text: "Georgia Shakeshaft", id: "Georgia Shakeshaft" },
        ],
        DEGREES: [
          {
            text: "Higher National Diploma (HND)",
            id: "Higher National Diploma (HND)",
          },
          { text: "Bachelor of Arts (BA)", id: "Bachelor of Arts (BA)" },
          {
            text: "Bachelor of Science (BSc)",
            id: "Bachelor of Science (BSc)",
          },
          { text: "Bachelor of Music (BMus)", id: "Bachelor of Music (BMus)" },
          { text: "Law (LLB)", id: "Law (LLB)" },
          { text: "Engineering (BEng)", id: "Engineering (BEng)" },
          { text: "Master of Arts (MA)", id: "Master of Arts (MA)" },
          { text: "Master of Science (MSc)", id: "Master of Science (MSc)" },
          {
            text: "Postgraduate Certificate (PGCE)",
            id: "Postgraduate Certificate (PGCE)",
          },
          { text: "PhD", id: "PhD" },
          { text: "History", id: "History" },
        ],
        CITIES: [
          { text: "London", id: "London" },
          { text: "Birmingham", id: "Birmingham" },
          { text: "Bristol", id: "Bristol" },
          { text: "Cambridge", id: "Cambridge" },
          { text: "Cardiff", id: "Cardiff" },
          { text: "Durham", id: "Durham" },
          { text: "Edinburgh", id: "Edinburgh" },
          { text: "Exeter", id: "Exeter" },
          { text: "Glasgow", id: "Glasgow" },
          { text: "Leeds", id: "Leeds" },
          { text: "Liverpool", id: "Liverpool" },
          { text: "Manchester", id: "Manchester" },
          { text: "Newcastle", id: "Newcastle" },
          { text: "Nottingham", id: "Nottingham" },
          { text: "Oxford", id: "Oxford" },
          { text: "Belfast", id: "Belfast" },
          { text: "Sheffield", id: "Sheffield" },
          { text: "Southampton", id: "Southampton" },
          { text: "Warwick", id: "Warwick" },
          { text: "York", id: "York" },
        ],
        UNIVERSITIES: [
          { text: "Top 5 UK Unis", id: "Top 5 UK Unis" },
          // {text: "Top 10", id: "Top 10"},
          { text: "Top 20 UK Unis", id: "Top 20 UK Unis" },
          { text: "Russell Group", id: "Russell Group" },
          { text: "Abertay University", id: "Abertay University" },
          { text: "Aberystwyth University", id: "Aberystwyth University" },
          {
            text: "Academy of Contemporary Music",
            id: "Academy of Contemporary Music",
          },
          {
            text: "Accrington and Rossendale College",
            id: "Accrington and Rossendale College",
          },
          {
            text: "Amsterdam Fashion Academy",
            id: "Amsterdam Fashion Academy",
          },
          { text: "Anglia Ruskin University", id: "Anglia Ruskin University" },
          {
            text: "Anglo European College of Chiropractic",
            id: "Anglo European College of Chiropractic",
          },
          { text: "Arden University", id: "Arden University" },
          {
            text: "Arts University Bournemouth",
            id: "Arts University Bournemouth",
          },
          { text: "ARU London", id: "ARU London" },
          { text: "Askham Bryan College", id: "Askham Bryan College" },
          { text: "Aston University", id: "Aston University" },
          { text: "Bangor University", id: "Bangor University" },
          {
            text: "Barking and Dagenham College",
            id: "Barking and Dagenham College",
          },
          { text: "Bath Spa University", id: "Bath Spa University" },
          {
            text: "Berkshire College of Agriculture",
            id: "Berkshire College of Agriculture",
          },
          { text: "Bicton College", id: "Bicton College" },
          { text: "BIMM", id: "BIMM" },
          {
            text: "Birkbeck, University of London",
            id: "Birkbeck, University of London",
          },
          {
            text: "Birmingham City University",
            id: "Birmingham City University",
          },
          { text: "Bishop Burton College", id: "Bishop Burton College" },
          {
            text: "Bishop Grosseteste University",
            id: "Bishop Grosseteste University",
          },
          { text: "Blackburn College", id: "Blackburn College" },
          {
            text: "Blackpool and the Fylde College",
            id: "Blackpool and the Fylde College",
          },
          { text: "Bournemouth University", id: "Bournemouth University" },
          { text: "BPP University", id: "BPP University" },
          { text: "Bradford College", id: "Bradford College" },
          {
            text: "Bridgwater and Taunton College",
            id: "Bridgwater and Taunton College",
          },
          {
            text: "Bristol, University West of England, (UWE)",
            id: "Bristol, University West of England, (UWE)",
          },
          {
            text: "British College of Osteopathic Medicine",
            id: "British College of Osteopathic Medicine",
          },
          { text: "Brunel University London", id: "Brunel University London" },
          {
            text: "Buckinghamshire New University",
            id: "Buckinghamshire New University",
          },
          { text: "Bury College", id: "Bury College" },
          {
            text: "Camberwell College of Arts, University of the Arts London",
            id: "Camberwell College of Arts, University of the Arts London",
          },
          {
            text: "Cambridge School of Visual and Performing Arts",
            id: "Cambridge School of Visual and Performing Arts",
          },
          {
            text: "Canterbury Christ Church University",
            id: "Canterbury Christ Church University",
          },
          {
            text: "Cardiff Metropolitan University",
            id: "Cardiff Metropolitan University",
          },
          { text: "Cardiff University", id: "Cardiff University" },
          {
            text: "Central Saint Martins, University of the Arts London",
            id: "Central Saint Martins, University of the Arts London",
          },
          {
            text: "Chelsea College of Arts, University of the Arts London",
            id: "Chelsea College of Arts, University of the Arts London",
          },
          { text: "Norwich, City College", id: "Norwich, City College" },
          { text: "Bristol, City College", id: "Bristol, City College" },
          {
            text: "Liverpool College, City of",
            id: "Liverpool College, City of",
          },
          {
            text: "City, University of London",
            id: "City, University of London",
          },
          {
            text: "Cleveland College of Art and Design",
            id: "Cleveland College of Art and Design",
          },
          { text: "Cliff College", id: "Cliff College" },
          { text: "Coleg Llandrillo", id: "Coleg Llandrillo" },
          { text: "Menai, Coleg", id: "Menai, Coleg" },
          { text: "Sir Gar, Coleg", id: "Sir Gar, Coleg" },
          {
            text: "College of Agriculture, Food and Rural Enterprise",
            id: "College of Agriculture, Food and Rural Enterprise",
          },
          { text: "West Anglia, College of", id: "West Anglia, College of" },
          { text: "Cornwall College", id: "Cornwall College" },
          {
            text: "Courtauld Institute of Art, University of London",
            id: "Courtauld Institute of Art, University of London",
          },
          { text: "Coventry University", id: "Coventry University" },
          { text: "De Montfort University", id: "De Montfort University" },
          { text: "Duchy College", id: "Duchy College" },
          { text: "Durham University", id: "Durham University" },
          { text: "East Riding College", id: "East Riding College" },
          { text: "Easton and Otley College", id: "Easton and Otley College" },
          { text: "Edge Hill University", id: "Edge Hill University" },
          {
            text: "Edinburgh Napier University",
            id: "Edinburgh Napier University",
          },
          {
            text: "ESCP Europe Business School",
            id: "ESCP Europe Business School",
          },
          {
            text: "European School of Osteopathy",
            id: "European School of Osteopathy",
          },
          { text: "Falmouth University", id: "Falmouth University" },
          {
            text: "Futureworks Manchester Media School",
            id: "Futureworks Manchester Media School",
          },
          {
            text: "Glasgow Caledonian University",
            id: "Glasgow Caledonian University",
          },
          { text: "Glasgow School of Art", id: "Glasgow School of Art" },
          { text: "Gloucestershire College", id: "Gloucestershire College" },
          {
            text: "Glyndwr University, Wrexham",
            id: "Glyndwr University, Wrexham",
          },
          {
            text: "Goldsmiths, University of London",
            id: "Goldsmiths, University of London",
          },
          { text: "London, GSM", id: "London, GSM" },
          { text: "Guildford College", id: "Guildford College" },
          { text: "Halesowen College", id: "Halesowen College" },
          { text: "Harper Adams University", id: "Harper Adams University" },
          {
            text: "Havering College of Further and Higher Education",
            id: "Havering College of Further and Higher Education",
          },
          { text: "Hereford College of Arts", id: "Hereford College of Arts" },
          { text: "Heriot-Watt University", id: "Heriot-Watt University" },
          { text: "Holy Cross College", id: "Holy Cross College" },
          { text: "Hugh Baird College", id: "Hugh Baird College" },
          { text: "Hull College", id: "Hull College" },
          {
            text: "Hult International Business School",
            id: "Hult International Business School",
          },
          { text: "Imperial College London", id: "Imperial College London" },
          {
            text: "Institute of Contemporary Music Performance",
            id: "Institute of Contemporary Music Performance",
          },
          {
            text: "Istituto Marangoni London",
            id: "Istituto Marangoni London",
          },
          { text: "Keele University", id: "Keele University" },
          {
            text: "Kensington College of Business",
            id: "Kensington College of Business",
          },
          {
            text: "King's College London, University of London",
            id: "King's College London, University of London",
          },
          { text: "Kingston College", id: "Kingston College" },
          { text: "Kingston University", id: "Kingston University" },
          {
            text: "Lakes College West Cumbria",
            id: "Lakes College West Cumbria",
          },
          { text: "Lancaster University", id: "Lancaster University" },
          { text: "Leeds Beckett University", id: "Leeds Beckett University" },
          { text: "Leeds City College", id: "Leeds City College" },
          { text: "Leeds College of Art", id: "Leeds College of Art" },
          { text: "Leeds College of Music", id: "Leeds College of Music" },
          { text: "Leeds Trinity University", id: "Leeds Trinity University" },
          { text: "Lincoln College", id: "Lincoln College" },
          {
            text: "Liverpool Hope University",
            id: "Liverpool Hope University",
          },
          {
            text: "Liverpool Institute for Performing Arts",
            id: "Liverpool Institute for Performing Arts",
          },
          {
            text: "Liverpool John Moores University",
            id: "Liverpool John Moores University",
          },
          {
            text:
              "London College of Communication, University of the Arts London",
            id:
              "London College of Communication, University of the Arts London",
          },
          {
            text: "London College of Fashion, University of the Arts London",
            id: "London College of Fashion, University of the Arts London",
          },
          { text: "London College, UCK", id: "London College, UCK" },
          {
            text: "London Metropolitan University",
            id: "London Metropolitan University",
          },
          {
            text: "London School of Business and Management",
            id: "London School of Business and Management",
          },
          {
            text:
              "London School of Economics and Political Science, University of London",
            id:
              "London School of Economics and Political Science, University of London",
          },
          {
            text: "London South Bank University",
            id: "London South Bank University",
          },
          { text: "Loughborough College", id: "Loughborough College" },
          { text: "Loughborough University", id: "Loughborough University" },
          { text: "Manchester College", id: "Manchester College" },
          {
            text: "Manchester Metropolitan University",
            id: "Manchester Metropolitan University",
          },
          { text: "Middlesex University", id: "Middlesex University" },
          { text: "Milton Keynes College", id: "Milton Keynes College" },
          {
            text: "Mont Rose College of Management and Sciences",
            id: "Mont Rose College of Management and Sciences",
          },
          { text: "Moulton College", id: "Moulton College" },
          { text: "Myerscough College", id: "Myerscough College" },
          {
            text: "Nazarene Theological College",
            id: "Nazarene Theological College",
          },
          { text: "Durham, New College", id: "Durham, New College" },
          {
            text: "New College of the Humanities",
            id: "New College of the Humanities",
          },
          { text: "Newcastle College", id: "Newcastle College" },
          { text: "Newcastle University", id: "Newcastle University" },
          { text: "Newham College London", id: "Newham College London" },
          { text: "Newman University", id: "Newman University" },
          { text: "Norland College Limited", id: "Norland College Limited" },
          { text: "North Lindsey College", id: "North Lindsey College" },
          {
            text: "Northbrook College Sussex",
            id: "Northbrook College Sussex",
          },
          { text: "Northumbria University", id: "Northumbria University" },
          {
            text: "Norwich University of the Arts",
            id: "Norwich University of the Arts",
          },
          {
            text: "Nottingham Trent University",
            id: "Nottingham Trent University",
          },
          { text: "NPTC Group", id: "NPTC Group" },
          {
            text: "Oxford Brookes University",
            id: "Oxford Brookes University",
          },
          {
            text: "Pearson College London (including Escape Studios)",
            id: "Pearson College London (including Escape Studios)",
          },
          { text: "Peter Symonds College", id: "Peter Symonds College" },
          { text: "Plumpton College", id: "Plumpton College" },
          { text: "Plymouth College of Art", id: "Plymouth College of Art" },
          {
            text: "Saint Mark and Saint John, University of",
            id: "Saint Mark and Saint John, University of",
          },
          { text: "Plymouth University", id: "Plymouth University" },
          { text: "Point Blank Ltd", id: "Point Blank Ltd" },
          {
            text: "Queen Margaret University, Edinburgh",
            id: "Queen Margaret University, Edinburgh",
          },
          {
            text: "Queen Mary University of London",
            id: "Queen Mary University of London",
          },
          {
            text: "Queen's University Belfast",
            id: "Queen's University Belfast",
          },
          { text: "Ravensbourne", id: "Ravensbourne" },
          {
            text: "Regent's University London",
            id: "Regent's University London",
          },
          {
            text: "Richmond, The American International University in London",
            id: "Richmond, The American International University in London",
          },
          { text: "Robert Gordon University", id: "Robert Gordon University" },
          { text: "Rose Bruford College", id: "Rose Bruford College" },
          {
            text: "Rotherham College of Arts and Technology",
            id: "Rotherham College of Arts and Technology",
          },
          { text: "Royal Academy of Dance", id: "Royal Academy of Dance" },
          {
            text: "Royal Academy of Music, University of London",
            id: "Royal Academy of Music, University of London",
          },
          {
            text: "Royal Agricultural University",
            id: "Royal Agricultural University",
          },
          {
            text: "Royal Central School of Speech and Drama",
            id: "Royal Central School of Speech and Drama",
          },
          { text: "Royal College of Music", id: "Royal College of Music" },
          {
            text: "Royal Conservatoire of Scotland",
            id: "Royal Conservatoire of Scotland",
          },
          {
            text: "Royal Holloway, University of London",
            id: "Royal Holloway, University of London",
          },
          {
            text: "Royal Northern College of Music",
            id: "Royal Northern College of Music",
          },
          {
            text: "Royal Veterinary College, University of London",
            id: "Royal Veterinary College, University of London",
          },
          {
            text: "Royal Welsh College of Music and Drama",
            id: "Royal Welsh College of Music and Drama",
          },
          { text: "Ruskin College", id: "Ruskin College" },
          {
            text: "Sandwell College of Further and Higher Education",
            id: "Sandwell College of Further and Higher Education",
          },
          { text: "Selby College", id: "Selby College" },
          { text: "Sheffield College", id: "Sheffield College" },
          {
            text: "Sheffield Hallam University",
            id: "Sheffield Hallam University",
          },
          {
            text: "SOAS, University of London",
            id: "SOAS, University of London",
          },
          { text: "Solihull College", id: "Solihull College" },
          { text: "South Essex College", id: "South Essex College" },
          { text: "South Thames College", id: "South Thames College" },
          {
            text: "Southampton Solent University",
            id: "Southampton Solent University",
          },
          {
            text: "Sparsholt College, Hampshire",
            id: "Sparsholt College, Hampshire",
          },
          { text: "Spurgeon's College", id: "Spurgeon's College" },
          {
            text: "SRUC Scotland’s Rural College",
            id: "SRUC Scotland’s Rural College",
          },
          {
            text: "Saint George's, University of London",
            id: "Saint George's, University of London",
          },
          {
            text: "Twickenham, Saint Mary's University",
            id: "Twickenham, Saint Mary's University",
          },
          { text: "Staffordshire University", id: "Staffordshire University" },
          { text: "Stockport College", id: "Stockport College" },
          {
            text: "Stranmillis University College",
            id: "Stranmillis University College",
          },
          {
            text: "Sussex Coast College Hastings",
            id: "Sussex Coast College Hastings",
          },
          { text: "Swansea University", id: "Swansea University" },
          { text: "Teesside University", id: "Teesside University" },
          {
            text:
              "The London Institute of Banking & Finance (formerly ifs University College)",
            id:
              "The London Institute of Banking & Finance (formerly ifs University College)",
          },
          { text: "University of Law", id: "University of Law" },
          {
            text: "Manchester, The University of",
            id: "Manchester, The University of",
          },
          {
            text: "Trinity Laban Conservatoire of Music and Dance",
            id: "Trinity Laban Conservatoire of Music and Dance",
          },
          {
            text: "Truro and Penwith College",
            id: "Truro and Penwith College",
          },
          {
            text: "UCL (University College London)",
            id: "UCL (University College London)",
          },
          { text: "Ulster, University of", id: "Ulster, University of" },
          {
            text: "University Campus Barnsley",
            id: "University Campus Barnsley",
          },
          {
            text: "Oldham, University Campus",
            id: "Oldham, University Campus",
          },
          {
            text: "University Centre Colchester at Colchester Institute",
            id: "University Centre Colchester at Colchester Institute",
          },
          {
            text: "Croydon, University Centre",
            id: "Croydon, University Centre",
          },
          {
            text: "Farnborough, University Centre",
            id: "Farnborough, University Centre",
          },
          {
            text: "Grimsby, University Centre",
            id: "Grimsby, University Centre",
          },
          {
            text: "University Centre Hartpury",
            id: "University Centre Hartpury",
          },
          {
            text: "Peterborough, University Centre",
            id: "Peterborough, University Centre",
          },
          {
            text: "University Centre Reaseheath",
            id: "University Centre Reaseheath",
          },
          {
            text: "St Helens, University Centre",
            id: "St Helens, University Centre",
          },
          {
            text: "Birmingham, University College",
            id: "Birmingham, University College",
          },
          {
            text: "University College of Estate Management",
            id: "University College of Estate Management",
          },
          {
            text: "University for the Creative Arts",
            id: "University for the Creative Arts",
          },
          { text: "Aberdeen, University of", id: "Aberdeen, University of" },
          { text: "Bath, University of", id: "Bath, University of" },
          {
            text: "Bedfordshire, University of",
            id: "Bedfordshire, University of",
          },
          {
            text: "Birmingham, University of",
            id: "Birmingham, University of",
          },
          { text: "Bolton, University of", id: "Bolton, University of" },
          { text: "Bradford, University of", id: "Bradford, University of" },
          { text: "Brighton, University of", id: "Brighton, University of" },
          { text: "Bristol, University of", id: "Bristol, University of" },
          {
            text: "Buckingham, University of",
            id: "Buckingham, University of",
          },
          { text: "Cambridge, University of", id: "Cambridge, University of" },
          {
            text: "Central Lancashire, University of",
            id: "Central Lancashire, University of",
          },
          { text: "Chester, University of", id: "Chester, University of" },
          {
            text: "Chichester, University of",
            id: "Chichester, University of",
          },
          { text: "Cumbria, University of", id: "Cumbria, University of" },
          { text: "Derby, University of", id: "Derby, University of" },
          { text: "Dundee, University of", id: "Dundee, University of" },
          {
            text: "East Anglia, University of",
            id: "East Anglia, University of",
          },
          {
            text: "East London, University of",
            id: "East London, University of",
          },
          { text: "Edinburgh, University of", id: "Edinburgh, University of" },
          { text: "Essex, University of", id: "Essex, University of" },
          { text: "Exeter, University of", id: "Exeter, University of" },
          { text: "Glasgow, University of", id: "Glasgow, University of" },
          {
            text: "Gloucestershire, University of",
            id: "Gloucestershire, University of",
          },
          { text: "Greenwich, University of", id: "Greenwich, University of" },
          {
            text: "Hertfordshire, University of",
            id: "Hertfordshire, University of",
          },
          {
            text: "Huddersfield, University of",
            id: "Huddersfield, University of",
          },
          { text: "Hull, University of", id: "Hull, University of" },
          { text: "Kent, University of", id: "Kent, University of" },
          { text: "Leeds, University of", id: "Leeds, University of" },
          { text: "Leicester, University of", id: "Leicester, University of" },
          { text: "Lincoln, University of", id: "Lincoln, University of" },
          { text: "Liverpool, University of", id: "Liverpool, University of" },
          {
            text: "University of London Institute in Paris",
            id: "University of London Institute in Paris",
          },
          {
            text: "Northampton, University of",
            id: "Northampton, University of",
          },
          {
            text: "Nottingham, University of",
            id: "Nottingham, University of",
          },
          { text: "Oxford, University of", id: "Oxford, University of" },
          {
            text: "Portsmouth, University of",
            id: "Portsmouth, University of",
          },
          { text: "Reading, University of", id: "Reading, University of" },
          {
            text: "Roehampton, University of",
            id: "Roehampton, University of",
          },
          { text: "Salford, University of", id: "Salford, University of" },
          { text: "Sheffield, University of", id: "Sheffield, University of" },
          {
            text: "South Wales, University of",
            id: "South Wales, University of",
          },
          {
            text: "Southampton, University of",
            id: "Southampton, University of",
          },
          {
            text: "Saint Andrews, University of",
            id: "Saint Andrews, University of",
          },
          { text: "Stirling, University of", id: "Stirling, University of" },
          {
            text: "Strathclyde, University of",
            id: "Strathclyde, University of",
          },
          { text: "University of Suffolk", id: "University of Suffolk" },
          {
            text: "Sunderland, University of",
            id: "Sunderland, University of",
          },
          { text: "Surrey, University of", id: "Surrey, University of" },
          { text: "Sussex, University of", id: "Sussex, University of" },
          {
            text: "Highlands and Islands, University of",
            id: "Highlands and Islands, University of",
          },
          {
            text: "West of Scotland, University of the",
            id: "West of Scotland, University of the",
          },
          {
            text: "University of Wales Trinity Saint David",
            id: "University of Wales Trinity Saint David",
          },
          { text: "Warwick, University of", id: "Warwick, University of" },
          {
            text: "West London, University of",
            id: "West London, University of",
          },
          {
            text: "Westminster, University of",
            id: "Westminster, University of",
          },
          {
            text: "Winchester, University of",
            id: "Winchester, University of",
          },
          {
            text: "Wolverhampton, University of",
            id: "Wolverhampton, University of",
          },
          { text: "Worcester, University of", id: "Worcester, University of" },
          { text: "York, University of", id: "York, University of" },
          { text: "Wakefield College", id: "Wakefield College" },
          { text: "Warwickshire College", id: "Warwickshire College" },
          {
            text: "Wimbledon College of Arts, University of the Arts London",
            id: "Wimbledon College of Arts, University of the Arts London",
          },
          {
            text: "Wirral Metropolitan College",
            id: "Wirral Metropolitan College",
          },
          {
            text: "Writtle University College",
            id: "Writtle University College",
          },
          { text: "York College", id: "York College" },
          {
            text: "York Saint John University",
            id: "York Saint John University",
          },
          { text: "Open University", id: "Open University" },
        ],
        SUBJECTS: [
          { text: "Accounting", id: "Accounting" },
          { text: "Aerospace engineering", id: "Aerospace engineering" },
          { text: "African studies", id: "African studies" },
          { text: "Agricultural sciences", id: "Agricultural sciences" },
          { text: "Agriculture", id: "Agriculture" },
          { text: "American studies", id: "American studies" },
          {
            text: "Anatomy, physiology & pathology",
            id: "Anatomy, physiology & pathology",
          },
          { text: "Ancient language studies", id: "Ancient language studies" },
          { text: "Animal science", id: "Animal science" },
          { text: "Anthropology", id: "Anthropology" },
          { text: "Archaeology", id: "Archaeology" },
          { text: "Architecture", id: "Architecture" },
          {
            text: "Architecture, Building and Planning",
            id: "Architecture, Building and Planning",
          },
          { text: "Astronomy", id: "Astronomy" },
          {
            text: "Aural &amp; oral sciences",
            id: "Aural &amp; oral sciences",
          },
          { text: "Biological Sciences", id: "Biological Sciences" },
          { text: "Biology", id: "Biology" },
          { text: "Biotechnology", id: "Biotechnology" },
          { text: "Botany", id: "Botany" },
          { text: "Building", id: "Building" },
          {
            text: "Business and Administrative Studies",
            id: "Business and Administrative Studies",
          },
          { text: "Business studies", id: "Business studies" },
          { text: "Celtic studies", id: "Celtic studies" },
          { text: "Ceramics &amp; glass", id: "Ceramics &amp; glass" },
          {
            text: "Chemical, process &amp; energy engineering",
            id: "Chemical, process &amp; energy engineering",
          },
          { text: "Chemistry", id: "Chemistry" },
          { text: "Chinese studies", id: "Chinese studies" },
          {
            text: "Cinematics &amp; photography",
            id: "Cinematics &amp; photography",
          },
          { text: "Civil engineering", id: "Civil engineering" },
          { text: "Classical Greek studies", id: "Classical Greek studies" },
          { text: "Clinical dentistry", id: "Clinical dentistry" },
          {
            text: "Comparative literary studies",
            id: "Comparative literary studies",
          },
          {
            text: "Complementary medicines, therapies &amp; well-being",
            id: "Complementary medicines, therapies &amp; well-being",
          },
          {
            text: "Computer generated visual &amp; audio effects",
            id: "Computer generated visual &amp; audio effects",
          },
          { text: "Computer science", id: "Computer science" },
          { text: "Computer Sciences", id: "Computer Sciences" },
          { text: "Crafts", id: "Crafts" },
          { text: "Creative Arts and Design", id: "Creative Arts and Design" },
          { text: "Dance", id: "Dance" },
          { text: "Design studies", id: "Design studies" },
          { text: "Development studies", id: "Development studies" },
          { text: "Drama", id: "Drama" },
          {
            text:
              "Eastern, Asiatic, African, American and Australasian Languages, Literature and Related Subjects",
            id:
              "Eastern, Asiatic, African, American and Australasian Languages, Literature and Related Subjects",
          },
          { text: "Economics", id: "Economics" },
          { text: "Education", id: "Education" },
          {
            text: "Electronic &amp; electrical engineering",
            id: "Electronic &amp; electrical engineering",
          },
          { text: "Engineering", id: "Engineering" },
          { text: "English studies", id: "English studies" },
          {
            text: "European Languages, Literature and Related Subjects",
            id: "European Languages, Literature and Related Subjects",
          },
          { text: "European Studies", id: "European Studies" },
          { text: "Finance", id: "Finance" },
          { text: "Fine art", id: "Fine art" },
          {
            text: "Food &amp; beverage studies",
            id: "Food &amp; beverage studies",
          },
          {
            text: "Forensic &amp; archaeological sciences",
            id: "Forensic &amp; archaeological sciences",
          },
          {
            text: "Forestry &amp; arboriculture",
            id: "Forestry &amp; arboriculture",
          },
          { text: "French studies", id: "French studies" },
          { text: "Games", id: "Games" },
          { text: "General engineering", id: "General engineering" },
          { text: "Genetics", id: "Genetics" },
          { text: "Geology", id: "Geology" },
          { text: "German studies", id: "German studies" },
          { text: "Health informatics", id: "Health informatics" },
          { text: "Heritage studies", id: "Heritage studies" },
          {
            text: "Historical and Philosophical Studies",
            id: "Historical and Philosophical Studies",
          },
          { text: "History by area", id: "History by area" },
          { text: "History by period", id: "History by period" },
          { text: "History by topic", id: "History by topic" },
          {
            text: "Hospitality, leisure, sport, tourism &amp; transport",
            id: "Hospitality, leisure, sport, tourism &amp; transport",
          },
          {
            text: "Human &amp; social geography",
            id: "Human &amp; social geography",
          },
          {
            text: "Human resource management",
            id: "Human resource management",
          },
          { text: "Imaginative writing", id: "Imaginative writing" },
          { text: "Information services", id: "Information services" },
          { text: "Information systems", id: "Information systems" },
          { text: "Italian studies", id: "Italian studies" },
          { text: "Japanese studies", id: "Japanese studies" },
          { text: "Journalism", id: "Journalism" },
          {
            text: "Landscape &amp; garden design",
            id: "Landscape &amp; garden design",
          },
          { text: "Latin studies", id: "Latin studies" },
          { text: "Law", id: "Law" },
          { text: "Law by area", id: "Law by area" },
          { text: "Law by topic", id: "Law by topic" },
          { text: "Linguistics", id: "Linguistics" },
          {
            text: "Linguistics, Classics and Related Subjects",
            id: "Linguistics, Classics and Related Subjects",
          },
          { text: "Management studies", id: "Management studies" },
          { text: "Maritime technology", id: "Maritime technology" },
          { text: "Marketing", id: "Marketing" },
          {
            text: "Mass Communication and Documentation",
            id: "Mass Communication and Documentation",
          },
          { text: "Materials science", id: "Materials science" },
          {
            text: "Materials technology not otherwise specified",
            id: "Materials technology not otherwise specified",
          },
          { text: "Mathematical Sciences", id: "Mathematical Sciences" },
          { text: "Mathematics", id: "Mathematics" },
          { text: "Mechanical engineering", id: "Mechanical engineering" },
          { text: "Media studies", id: "Media studies" },
          { text: "Medical technology", id: "Medical technology" },
          { text: "Medicine and Dentistry", id: "Medicine and Dentistry" },
          { text: "Metallurgy", id: "Metallurgy" },
          { text: "Microbiology", id: "Microbiology" },
          { text: "Minerals technology", id: "Minerals technology" },
          {
            text: "Modern Middle Eastern studies",
            id: "Modern Middle Eastern studies",
          },
          {
            text: "Molecular biology, biophysics &amp; biochemistry (C700)",
            id: "Molecular biology, biophysics &amp; biochemistry (C700)",
          },
          { text: "Music", id: "Music" },
          { text: "Naval architecture", id: "Naval architecture" },
          { text: "Nursing", id: "Nursing" },
          { text: "Nutrition", id: "Nutrition" },
          { text: "Office skills", id: "Office skills" },
          { text: "Operational research", id: "Operational research" },
          { text: "Ophthalmics", id: "Ophthalmics" },
          { text: "Other Asian studies", id: "Other Asian studies" },
          {
            text: "Pharmacology, toxicology &amp; pharmacy",
            id: "Pharmacology, toxicology &amp; pharmacy",
          },
          { text: "Philosophy", id: "Philosophy" },
          {
            text: "Physical geographical sciences",
            id: "Physical geographical sciences",
          },
          { text: "Physical Sciences", id: "Physical Sciences" },
          { text: "Physics", id: "Physics" },
          {
            text: "Planning (urban, rural &amp; regional)",
            id: "Planning (urban, rural &amp; regional)",
          },
          { text: "Politics", id: "Politics" },
          { text: "Polymers &amp; textiles", id: "Polymers &amp; textiles" },
          { text: "Portuguese studies", id: "Portuguese studies" },
          { text: "Pre-clinical dentistry", id: "Pre-clinical dentistry" },
          {
            text: "Production &amp; manufacturing engineering",
            id: "Production &amp; manufacturing engineering",
          },
          { text: "Psychology", id: "Psychology" },
          { text: "Publicity studies", id: "Publicity studies" },
          { text: "Publishing", id: "Publishing" },
          {
            text: "Research &amp; study skills in education",
            id: "Research &amp; study skills in education",
          },
          {
            text: "Russian &amp; East European studies",
            id: "Russian &amp; East European studies",
          },
          { text: "Scandinavian studies", id: "Scandinavian studies" },
          {
            text: "Science of aquatic &amp; terrestrial environments",
            id: "Science of aquatic &amp; terrestrial environments",
          },
          { text: "Social policy", id: "Social policy" },
          { text: "Social Studies", id: "Social Studies" },
          { text: "Social work", id: "Social work" },
          { text: "Sociology", id: "Sociology" },
          { text: "Software engineering", id: "Software engineering" },
          { text: "South Asian studies", id: "South Asian studies" },
          { text: "Spanish studies", id: "Spanish studies" },
          {
            text: "Sport &amp; exercise science",
            id: "Sport &amp; exercise science",
          },
          { text: "Statistics", id: "Statistics" },
          {
            text: "Subjects Allied to Medicine",
            id: "Subjects Allied to Medicine",
          },
          { text: "Technologies", id: "Technologies" },
          {
            text: "Theology &amp; religious studies",
            id: "Theology &amp; religious studies",
          },
          { text: "Training teachers", id: "Training teachers" },
          {
            text: "Veterinary Sciences, Agriculture and Related Subjects",
            id: "Veterinary Sciences, Agriculture and Related Subjects",
          },
          { text: "Zoology", id: "Zoology" },
        ],
        NOTICE_PERIODS: [
          { text: "None", id: "None" },
          { text: "1 week", id: "1 week" },
          { text: "2 weeks", id: "2 weeks" },
          { text: "3 weeks", id: "3 weeks" },
          { text: "1 month", id: "1 month" },
          { text: "2 months", id: "2 months" },
          { text: "3 months", id: "3 months" },
          { text: "6 months", id: "6 months" },
        ],
        GRADES: [
          { text: "1st" },
          { text: "2:1" },
          { text: "2:2" },
          { text: "3rd" },
          { text: "Ordinary Degree (Pass)" },
          { text: "Fail" },
          { text: "Distinction" },
          { text: "Merit" },
          { text: "Other" },
        ],
      },
    };

    let user = JSON.parse(localStorage.getItem("user"));

    if (user !== null && user !== undefined) {
      this.setState({ user: user });
      window.globalVars.user = user;
      this.getLatestUser();
      this.getLatestTags();
    } else {
      setTimeout(
        function() {
          let user = JSON.parse(localStorage.getItem("user"));

          if (user !== null && user !== undefined) {
            this.setState({ user: user });
            window.globalVars.user = user;
            this.getLatestUser();
          }

          this.getLatestTags();
        }.bind(this),
        250
      );
    }
  }

  getConfigs = (force) => {
    let configs = JSON.parse(localStorage.getItem("configs"));

    if (!configs || force || Date.now() > configs.timestamp + 30000) {

      window.globalVars.server.api({
        method: "get",
        url: "api/config",
        then: function(res) {
          if (res.data) {
            localStorage.setItem(
              "configs",
              JSON.stringify({
                timestamp: Date.now(),
                configs: res.data,
              })
            );

            if (
              res.data.bullhornDown &&
              res.data.bullhornDown === "true" &&
              window.location.href.indexOf("maintenance") === -1
            ) {
              window.location.href = "/#maintenance";
            } else if (
              res.data.bullhornDown &&
              res.data.bullhornDown === "false" &&
              window.location.href.indexOf("maintenance") !== -1
            ) {
              window.location.href = "/";
            }
          }

        }.bind(this),
        catch: function(e) {}.bind(this),
        ignoreUnauthorized: true,
      });
    } 
  };

  getLatestTags = () => {
    let tags = JSON.parse(localStorage.getItem("tags"));

    if (
      tags === null ||
      tags === undefined ||
      tags.vacancyTags === undefined ||
      tags.candidateTags === undefined ||
      Date.now() > tags.timestamp + 900000
    ) {
      window.globalVars.server.api({
        method: "get",
        url: "api/tags/all",
        then: function(res) {
          let tags = res.data.data.all;
          let featuredTags = res.data.data.featured;
          let vacancyTags = [];
          let candidateTags = [];
          let featuredCandidateTags = [];
          let featuredVacancyTags = [];

          for (let i = 0; i < tags.length; i++) {
            let categoryName = tags[i].categoryName;

            tags[i] = {
              id: tags[i].tagId.toString(),
              text: tags[i].tagName,
              cat: tags[i].categoryName,
            };

            if (categoryName[0] === "J") {
              vacancyTags.push(tags[i]);
            } else {
              candidateTags.push(tags[i]);
            }
          }

          for (let i = 0; i < featuredTags.length; i++) {
            let categoryName = featuredTags[i].category;

            let fullCategoryName = null;

            for (let j = 0; j < tags.length; j++) {
              //
              if (
                tags[j].text.split(" - ")[0] ===
                featuredTags[i].tagName.split(" - ")[0]
              ) {
                fullCategoryName = tags[j].cat;
                break;
              }
            }

            featuredTags[i] = {
              id: featuredTags[i]._id.toString(),
              text: featuredTags[i].tagName,
              count: featuredTags[i].count,
              type: featuredTags[i].category,
              category: featuredTags[i].tagName,
            };

            if (fullCategoryName) {
              featuredTags[i].catName = fullCategoryName.split(" ")[1];
            }

            if (categoryName === "G") {
              featuredCandidateTags.push(featuredTags[i]);
            } else {
              featuredVacancyTags.push(featuredTags[i]);
            }
          }

          let dateNow = Date.now();

          let tagsStorage = {
            timestamp: dateNow,
            tags: tags,
            candidateTags: candidateTags,
            vacancyTags: vacancyTags,
            featured: {
              candidate: featuredCandidateTags,
              vacancy: featuredVacancyTags,
            },
          };

          localStorage.setItem("tags", JSON.stringify(tagsStorage));
        }.bind(this),
        catch: function(e) {}.bind(this),
        ignoreUnauthorized: true,
      });
    }

    let newTags = JSON.parse(localStorage.getItem("newTags"));
    if (
      newTags === null ||
      newTags === undefined ||
      newTags.vacancyTags === undefined ||
      newTags.candidateTags === undefined
    ) {
      window.globalVars.server.api({
        method: "get",
        url: "api/tags/get-all",
        then: function(res) {
          let tags = res.data.data.all;

          tags = tags.map((item) => {
            return {
              id: item._id,
              text: item.tagName,
              cat: item.type,
            };
          });

          let dateNow = Date.now();

          let tagsStorage = {
            timestamp: dateNow,
            tags: tags,
            candidateTags: tags,
            vacancyTags: tags,
          };

          localStorage.setItem("newTags", JSON.stringify(tagsStorage));
        }.bind(this),
        catch: function(e) {}.bind(this),
        ignoreUnauthorized: true,
      });
    }
  };

  getLatestUser = () => {
    if (window.globalVars.preventGetUser) {
      return;
    }

    window.globalVars.preventGetUser = true;
    setTimeout(function() {
      window.globalVars.preventGetUser = false;
    }, 300);

    let authToken = localStorage.getItem("authToken");

    if (authToken !== null && authToken !== undefined) {
      window.globalVars.server.api({
        method: "post",
        url: "api/v2/user/current",
        then: function(res) {
          let user = res.data.data.user;

          if (user.locked) {
            if (
              localStorage.getItem("adminBussinessToken") ||
              localStorage.getItem("adminToken")
            ) {
            } else {
              window.globalVars.server.logout();
              return;
            }
          }

          this.setState({
            loggedIn: true,
            user: user,
          });

          if (!Utilities.isENU(user.wishlist)) {
            if (user.type === 4) {
              user.wishlist = user.wishlist.map((item) => {
                return item;
              });
              user.wishlist = user.wishlist.filter(Number);
              localStorage.setItem(
                "vacancyWishList",
                JSON.stringify(user.wishlist)
              );
            } else {
              user.wishlist = user.wishlist.map((item) => {
                if (item.indexOf("||") !== -1) {
                  let n = item.split("||");
                  if (n[n.length - 1] !== "undefined") {
                    return item;
                  }
                }
              });
              user.wishlist = user.wishlist.filter(function(element) {
                return element !== undefined;
              });
              localStorage.setItem(
                "candidateWishList",
                JSON.stringify(user.wishlist)
              );
            }

            let wishListCountDom = document.getElementsByClassName(
              "wish-list-count"
            );

            if (user.wishlist) {
              if (wishListCountDom[0])
                wishListCountDom[0].innerHTML = user.wishlist.length;
              if (wishListCountDom[1])
                wishListCountDom[1].innerHTML = user.wishlist.length;
            }
          }

          let guestWishList = JSON.parse(localStorage.getItem("guestWishList"));
          let guestWishListType = JSON.parse(
            localStorage.getItem("guestWishListType")
          );

          if (
            !Utilities.isENU(guestWishList) &&
            !Utilities.isENU(guestWishListType)
          ) {
            window.globalVars.server.api({
              method: "post",
              url: "api/user/wishlist/rewrite",
              data: {
                ids: guestWishList,
              },
              then: function(res) {
                if (guestWishListType === "candidate") {
                  localStorage.setItem(
                    "candidateWishList",
                    JSON.stringify(guestWishList)
                  );
                } else {
                  localStorage.setItem(
                    "vacancyWishList",
                    JSON.stringify(guestWishList)
                  );
                }

                localStorage.removeItem("guestWishList");
                localStorage.removeItem("guestWishListType");
                window.location.reload();
              }.bind(this),
              catch: function(e) {}.bind(this),
            });
          }

          if (!Utilities.isENU(user.applications)) {
            localStorage.setItem(
              "applications",
              JSON.stringify(user.applications)
            );
          }

          if (user.wishlistNames) {
            user.wishlistNames.unshift("Wish List 1");
            localStorage.setItem(
              "wishlistNames",
              JSON.stringify(user.wishlistNames)
            );
          }

          setTimeout(function() {
            delete user.wishlist;
            delete user.applications;
            delete user.wishlistNames;
            delete user.cvs;
            localStorage.setItem("user", JSON.stringify(user));

            window.globalVars.loggedIn = true;
            window.globalVars.user = user;
          });
        }.bind(this),
        catch: function(e) {
          window.globalVars.loggedIn = false;
          this.setState({ loggedIn: false });
        }.bind(this),
        ignoreUnauthorized: true,
      });
    }
  };

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <div
              data-ie={this.state.ie}
              data-ios={isIOS}
              data-mobile={isMobile}
              data-url={this.state.currentUrl}
            >
              {/*Maintenance*/}
              <Router history={history}>
                <div>
                  {!new_routes.includes(this.state.pathname) && (
                    <Navbar
                      history={history}
                      loggedIn={this.state.loggedIn}
                      user={this.state.user}
                      currentUrl={this.state.currentUrl}
                    />
                    )}
                  
                  <Switch>
                    <Route path="/" component={Home} exact></Route>

                    <CustomAuthRoute
                      path="/login/:type?"
                      component={Login}
                    ></CustomAuthRoute>
                    <Route
                      exact
                      path="/linkedinlogin"
                      component={LinkedInPage}
                    ></Route>
                    <Route
                      exact
                      path="/linkedin"
                      component={LinkedInCallback}
                    />
                    <CustomAuthRoute
                      path="/signup"
                      component={Signup}
                    ></CustomAuthRoute>
                    <BusinessRoute
                      path="/post-free-job"
                      component={SignupPostJobFree}
                    ></BusinessRoute>
                    {/* <CustomAuthRoute path="/login" component={ Login } ></CustomAuthRoute>
-                                       <CustomAuthRoute path="/signup" component={Login} ></CustomAuthRoute> */}
                    <CustomAuthRoute
                      path="/quick-apply-signup"
                      component={QuickApplySignup}
                    ></CustomAuthRoute>

                    <Route
                      exact
                      path="/directory/jobs"
                      component={() => <Directory type="jobs" />}
                    ></Route>
                    <Route
                      exact
                      path="/directory/grads"
                      component={() => <Directory type="grads" />}
                    ></Route>

                    <Route
                      path="/terms/employers"
                      component={() => <Static type="employer-terms" />}
                    ></Route>
                    <Route
                      path="/terms/jobseekers"
                      component={() => <Static type="graduate-terms" />}
                      exact
                    ></Route>
                    <Route
                      path="/terms/graduates"
                      component={() => <Static type="graduate-terms" />}
                      exact
                    ></Route>
                    <Route
                      path="/terms/potnoodle-graduates"
                      component={() => <Static type="potnoodle-graduates" />}
                      exact
                    ></Route>
                    <Route
                      path="/privacy"
                      component={() => <Static type="privacy" />}
                      exact
                    ></Route>
                    <Route
                      path="/acceptable-use"
                      component={() => <Static type="acceptable-use" />}
                      exact
                    ></Route>
                    <Route path="/faq" component={() => <FAQ />} exact></Route>
                    <Route
                      path="/faq-videos"
                      component={() => <FAQVideos />}
                      exact
                    ></Route>

                    <Route
                      path="/graduates/post/:jobId/:id/:applicationId/:stack"
                      component={GraduateDetails}
                    ></Route>
                    <Route
                      path="/graduates/post/:id"
                      component={GraduateDetails}
                    ></Route>
                    <Route path="/graduates/cv" component={GraduateCV}></Route>
                    <Route
                      path="/graduates/ny"
                      component={() => <GraduateSearch city="ny" />}
                    ></Route>
                    {/* <BusinessRoute path="/graduates" component={ GraduateSearch }></BusinessRoute> */}
                    <BusinessRoute
                      path="/graduates"
                      component={GraduateList}
                    ></BusinessRoute>
                    <Route
                      path="/advance-search-graduates"
                      component={AdvanceGraduateSearch}
                    ></Route>
                    {/* <Route path="/advance-search-graduates" component={ AdvanceGradSearch }></Route> */}
                    <Route
                      path="/graduates/:type/:tags"
                      component={GraduateSearch}
                    ></Route>
                    {/* <Route path="/cv-search" component={ CVSearch }></Route> */}
                    <Route path="/cv-search" component={CVSearch}></Route>
                    <Route
                      path="/jobs/ny/post/"
                      component={() => <VacancyDetails city="ny" />}
                    ></Route>
                    <Route
                      path="/jobs/ny"
                      component={() => <OutsideJobSearch city="ny" />}
                    ></Route>
                    {/* <Route
                      path="/jobs/potnoodle"
                      component={() => <JobSearch preloadTag="Pot Noodle" />}
                      exact
                    ></Route> */}
                    <PrivateRoute
                      path="/jobs/post/view/:id/statistics/:type"
                      component={JobStatistics}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/jobs/post/edit/"
                      component={JobOnboarding}
                    ></PrivateRoute>
                    <Route path="/jobs/post/view/" component={JobView}></Route>
                    
                    <GraduateRoute
                      path="/jobs/:jobtype?/:jobId?"
                      component={JobList}
                    ></GraduateRoute>
                    <Route path="/readability-checker" component={ReadabilityChecker}></Route>
                    <Route path="/salary-predictor" component={SalaryPredictor}></Route>
                    <Route path="/historical-salary" component={HistoricalSalary}></Route>
                    <Route path="/career-hub" component={CareerHub} exact></Route>
                    <Route path="/employer-hub" component={CareerHub} exact></Route>

                    <Route path="/job-search-new" component={JobSearchNew} />
                    <Route path="/grad-search-new" component={GradSearchNew} />
                    <Route
                      path="/jobs/:type/:tags"
                      component={JobSearch}
                    ></Route>
                    <Route
                      path="/advance-search"
                      component={AdvanceSearch}
                    ></Route>
                    <Route
                      path="/trynow/:sector?"
                      component={Marketing}
                    ></Route>
                    {/* <Route path="/advance-search" component={AdvanceJobSearch} ></Route> */}
                    <Route path="/invite/:token" component={Invite}></Route>
                    {/* <Route path="/restore/email" exact component={() => <RestorePassword type="email" />}></Route>
                                        <Route path="/restore/password/:userId/:hash" exact component={() => <RestorePassword type="password" />}></Route> */}
                    <Route
                      path="/restore/email"
                      component={ForgotPassword}
                    ></Route>
                    <Route
                      path="/restore/password/:userId/:hash"
                      exact
                      component={ResetPassword}
                    ></Route>
                    <Route path="/insight" component={BlogList} exact></Route>
                    <Route path="/about" component={About} exact></Route>
                    <Route path="/trackevent" component={TrackEvent} exact></Route>
                    <Route
                      path="/insight/post/"
                      component={BlogDetails}
                    ></Route>
                    <Route
                      path="/career-hub/post/"
                      component={CareerHubDetails}
                    ></Route>
                    <Route
                      path="/insight/edit/:id"
                      component={BlogEdit}
                    ></Route>
                    <PrivateRoute
                      path="/saved/jobs"
                      component={() => <WishList type="vacancy" />}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/saved/grads"
                      component={() => <WishList type="candidate" />}
                    ></PrivateRoute>
                    <Route
                      path="/jobs-in/post/"
                      component={IndustryDetails}
                    ></Route>
                    <PrivateRoute
                      path="/dashboard/"
                      component={GraduateDashboard}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/graduate/onboarding"
                      component={GraduateOnboarding}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/business/onboarding"
                      component={BusinessOnboarding}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/business/company"
                      component={CompanySettings}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/graduate/settings/:type?"
                      component={GraduateSettings}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/my-profile/business/"
                      component={BusinessDashboard}
                      exact
                    ></PrivateRoute>
                    <Route
                      path="/admin/login-as/:id"
                      component={AdminLoginAs}
                    ></Route>
                    <AdminRoute
                      path="/admin/"
                      component={AdminDashboard}
                    ></AdminRoute>
                    <Route
                      path="/admin-agent/"
                      component={AdminAgentDashboard}
                    ></Route>
                    <Route
                      path="/admin-sonic-job/"
                      component={AdminSonicDashboard}
                    ></Route>
                    <Route path="/contact-us/" component={ContactUs}></Route>
                    <PrivateRoute
                      path="/messages/"
                      component={Messages}
                    ></PrivateRoute>
                    {/*<Route path="/potnoodle/" component={ PotNoodleMarketing }></Route>*/}
                    <Route path="/pepsico/" component={PepsiCo}></Route>
                    <Route path="/kickstart">
                      <Redirect to="/government-kickstart-scheme" />
                    </Route>
                    <Route
                      path="/government-kickstart-scheme"
                      component={GovKickStarterScheme}
                    ></Route>
                    <Route
                      path="/apply-kickstart-scheme"
                      component={ApplyGovKickStarterScheme}
                    ></Route>
                    <Route path="/thank-you/" component={ThankyouPage}></Route>
                    <Route
                      path="/manage-emails/:id/:token"
                      component={EmailNewsletter}
                    ></Route>
                    <Route
                      path="/subscription/:type/:action/:email/:token"
                      component={SubscriptionManager}
                    ></Route>
                    <Route
                      path="/email-signup/:email/:token"
                      component={EmailSignup}
                    ></Route>
                    <Route
                      path="/s/:shortUrl"
                      component={URLShortner}
                    ></Route>
                    <Route
                      path="/interview/schedule/:interviewId/:token"
                      component={InterviewScheduler}
                    ></Route>
                    <PrivateRoute
                      path="/pricing"
                      component={Pricing}
                    ></PrivateRoute>
                    <Route
                      path="/job-ad-writer"
                      component={GPTJobDescription}
                    ></Route>
                    <Route
                      path="/services/:section"
                      component={Services}
                    ></Route>
                    <Route
                      path="/business-pricing/:type?"
                      component={PricingNew}
                    ></Route>
                    <Route
                      path="/401/"
                      component={() => <Error code="401" />}
                    ></Route>
                    <Route
                      path="/newsletter/marketing/:email"
                      component={() => (
                        <MarketingSubscription key="marketing-subscription" />
                      )}
                    ></Route>
                    <Route
                      path="/unsubscribe/:email"
                      component={MarketingSubscription}
                    ></Route>
                    <Route
                      path="/unsubscribe"
                      component={MarketingSubscription}
                    ></Route>
                    <Route
                      path="/verify/:token"
                      component={EmailVerification}
                    ></Route>
                    {/* <Route path="/deloitte" component={ Deloitte }></Route> */}
                    <Route
                      path="/update-email-verification/:type/:token"
                      component={UpdateEmailVerification}
                    ></Route>
                    <PrivateRoute
                      path="/business/application/settings"
                      component={CompnayApplicationSettings}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/my-subscription"
                      component={MySubscription}
                    ></PrivateRoute>
                    <Route
                      path="/profile/:token"
                      component={CompanyProfileCompletion}
                    ></Route>
                    <Route component={Error} />
                    {/*<Route component={ Redirecter } />*/}
                  </Switch>
                  {!new_routes.includes(this.state.pathname) && <Footer />}
                  <CookieConsent
                    enableDeclineButton
                    location="bottom"
                    buttonText="Accept"
                    declineButtonText="Decline"
                  >
                    The website uses cookies to enhance the User experience. To
                    find out more, read our updated{" "}
                    <Link to="/privacy">cookie policy</Link>.
                  </CookieConsent>
                </div>
              </Router>
            </div>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

// App.contextTypes = {
//     router: () => {
//         return React.PropTypes.func.isRequired
//     }
// }

export default App;
