let keys = {
    // stripeSecret : "pk_test_LROHDtRGGy5pptQpdjgYhLMm00KcBKwv8v",
    stripeSecret : "pk_live_uJxcVPoHoSyRkHLFibsOr8ae000rl6Z2Mt",
    // domainUploadMS :  "https://gradbay-ms-upload.hestawork.com/",
    domainUploadMS: "https://gradbay-ms-upload.gradbay.com/",
    adzuna_app_id: 'd6797ddb',
    adzuna_app_key: 'a858d42eb5d95103a929f3aaf877fead',
    
}



// console.log("DEBUG :  ~ file: keys.js ~ line 7 ~ process.env", process.env.NODE_ENV);
if (process.env.NODE_ENV === "development") {
    // keys.adzuna_app_id = 'fb7915f9',
    // keys.adzuna_app_key = 'fa7e9dbb0b73c814ca3ce253443d60ce',
    keys.stripeSecret = "pk_test_LROHDtRGGy5pptQpdjgYhLMm00KcBKwv8v";
    // keys.domainUploadMS = "https://gradbay-ms-upload.hestawork.com/"
    keys.domainUploadMS = "http://localhost:8082/"
    console.log("Loaded Local config")
} else {
    console.log("Loaded Dev config")
}

module.exports = keys;