import React from 'react';
import Slider from "react-slick";
import './JobType.css';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import CreativeJobsImg from './../images/job-type/Creative-Outline800.webp';
import ExpressJobsImg from './../images/job-type/express-job.webp';
import TechJobsImg from './../images/job-type/Machine_Learning-outline800.webp';
import LondonJobsImg from './../images/job-type/London-A.svg';
import HighEarningJobsImg from './../images/job-type/Client-Facing-Outline800.webp';
import MediaJobsImg from './../images/job-type/Film-Streaming-outline800.webp';
import DigitalJobsImg from './../images/job-type/Digital-outline800.webp';
import RecruitmentJobsImg from './../images/job-type/Recruitment-Outline.svg';
import AnalyticalGradsImg from './../images/job-type/Analytical-Outline800.webp';
import CreativeGradsImg from './../images/job-type/Creative-Outline800.webp';
import ClientFacingGradsImg from './../images/job-type/Client-Facing-Outline800.webp';
import TechGradsImg from './../images/job-type/Tech-B-outline800.webp';
import useSearch from 'v2/hooks/useSearch';

const JobType = (props) => {

   const search = useSearch();

   const onClickCat = (data) => {
      search.clearFilterJob();
      search.setJobSearchParams(data);
      window.location.href = `/jobs/gradbay`;
   }

   const onClickGradCat = (data) => {
      search.clearFilterGrad();
      search.setGradSearchParams(data);
      window.location.href = `/graduates`;
   }

   const settings1 = {
      nextArrow: <FiChevronRight />,
      prevArrow: <FiChevronLeft />,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 1000,
      autoplay: false,
      autoplaySpeed: 2000,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
         {
            breakpoint: 1000,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
            }
         },
         {
            breakpoint: 560,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            }
         },
      ]
   };

   return (
      <div className="jobtype-page">
         <div className="container">
            <h2 className='mt-4'>Trending</h2>
            <div className="reviews-wrapper">
               {props.type === 'business' ?
                  <Slider {...settings1} className="review-contain home_job_card">
                     <div onClick={() => onClickGradCat({ job_type: [{ id: 'Analytical', text: 'Analytical' }] })} className="category-card">
                        <figure>
                           <img src={AnalyticalGradsImg} alt="Analytical" loading='lazy' />
                        </figure>
                        <h3>Analytical Grads</h3>
                     </div>
                     <div onClick={() => onClickGradCat({ job_type: [{ id: 'Creative', text: 'Creative' }] })} className="category-card">
                        <figure>
                           <img src={CreativeGradsImg} alt="Creative" loading='lazy' />
                        </figure>
                        <h3>Creative Grads</h3>
                     </div>
                     <div onClick={() => onClickGradCat({ job_type: [{ id: 'Client Facing / High Earning', text: 'Client Facing / High Earning' }] })} className="category-card">
                        <figure>
                           <img src={ClientFacingGradsImg} alt="data" loading='lazy' />
                        </figure>
                        <h3>Client Facing Grads</h3>
                     </div>
                     <div onClick={() => onClickGradCat({ sector: [{ id: 'Tech', text: 'Tech' }] })} className="category-card">
                        <figure>
                           <img src={TechGradsImg} alt="Target Driven" loading='lazy' />
                        </figure>
                        <h3>Tech Grads</h3>
                     </div>
                  </Slider>
                  :
                  <Slider {...settings1} className="review-contain home_job_card">
                     <div
                        onClick={() => {
                           window.location.href = `/jobs/express`;
                        }}
                        className="category-card"
                     >
                        <figure>
                           <img src={ExpressJobsImg} alt="Express" loading='lazy' />
                        </figure>
                        <h3>Express Jobs</h3>
                     </div>
                     <div onClick={() => onClickCat({ job_type: [{ id: 'Creative', text: 'Creative' }] })} className="category-card">
                        <figure>
                           <img src={CreativeJobsImg} alt="Creative" loading='lazy' />
                        </figure>
                        <h3>Creative Jobs</h3>
                     </div>
                     <div onClick={() => onClickCat({ sector: [{ id: 'Tech', text: 'Tech' }] })} className="category-card">
                        <figure>
                           <img src={TechJobsImg} alt="Analytical" loading='lazy' />
                        </figure>
                        <h3>Tech Jobs</h3>
                     </div>
                     <div onClick={() => onClickCat({ location: [{ id: 'London', text: 'London' }] })} className="category-card">
                        <figure>
                           <img src={LondonJobsImg} alt="data" loading='lazy' />
                        </figure>
                        <h3>London Jobs</h3>
                     </div>
                     <div onClick={() => onClickCat({ sector: [{ id: 'Media, Arts and Culture', text: 'Media, Arts and Culture' }] })} className="category-card">
                        <figure>
                           <img src={MediaJobsImg} alt="media" loading='lazy' />
                        </figure>
                        <h3>Media Jobs</h3>
                     </div>
                     <div onClick={() => onClickCat({ job_type: [{ id: 'Client Facing / High Earning', text: 'Client Facing / High Earning' }] })} className="category-card">
                        <figure>
                           <img src={HighEarningJobsImg} alt="Creative" loading='lazy' />
                        </figure>
                        <h3>High-Earning Jobs</h3>
                     </div>
                     <div onClick={() => onClickCat({ job_type: [{ id: 'Digital', text: 'Digital' }] })} className="category-card">
                        <figure>
                           <img src={DigitalJobsImg} alt="Creative" loading='lazy' />
                        </figure>
                        <h3>Digital Jobs</h3>
                     </div>
                     <div onClick={() => onClickCat()} className="category-card">
                        <figure>
                           <img src={RecruitmentJobsImg} alt="Creative" loading='lazy' />
                        </figure>
                        <h3>Recruitment Jobs</h3>
                     </div>
                  </Slider>
               }
            </div>
         </div>
      </div>
   );
}

export default JobType;
