import React from "react";
import Fonts from "v2/Fonts";
import Styles from "v2/Styles";
import { useSelector } from 'react-redux'
import { ThemeProvider } from "styled-components";
import { ToastContainer, ToastStore } from 'react-toasts';

const themes = {
	defaultTheme: {
		colors: {
			primaryColor: "rgb(69, 246, 166)",
			bgColor: "rgb(245, 245, 245)",
			textColor: "rgb(34, 34, 34)",
			grayColor:'rgb(245, 245, 245)',
			darkGray:'#424242',
			errorColor:'#d92020',
			white:'#fff',
			purpleColor:'rgb(101, 27, 160)',
			blackLight:'rgba(0,0,0,0.87)',
			darkBlue:'#2a4673',
			purple:'#bd99ff',
			pink:'#ffb4b5',
			lightBlue:'#c3ffff'

		},
		fonts: ["sans-serif", "Montserrat"],
		fontSizes: {
			small: "1em",
			medium: "2em",
			large: "3em"
		}
	},
	darkTheme: {
		colors: {
			primaryColor: "rgba(0,0,0,0.87)",
			bgColor: "#272828",
			textColor: "#fff"
		},
		fonts: ["sans-serif", "Montserrat"],
		fontSizes: {
			small: "1em",
			medium: "2em",
			large: "3em"
		}
	}
}

export default ({ children }) => {
	const is_dark_theme = useSelector(state => state.core.is_dark_theme)
  	return <ThemeProvider theme={themes[is_dark_theme ? 'darkTheme' : 'defaultTheme'] || themes.defaultTheme}>
	  	<Fonts/>
		<Styles />
	  	{children}
		<ToastContainer position={ToastContainer.POSITION.TOP_RIGHT} store={ToastStore}/>
	</ThemeProvider>
}