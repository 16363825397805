import React, { useState, useEffect } from "react";
import Loadable from "react-loadable";
import LoaderEmpty from "./components/LoaderEmpty.js";
import JobType from "./components/JobType";
import Cookies from "js-cookie";
import HomeSliderNew from "HomeSlider.js";
import Statistics from "./components/Statistics";
import UserTypeWelcomePopup from "components/UserTypeWelcomePopup.js";
import Sustainability from "components/Sustainability.js";
import BlogFeeds from "components/BlogFeeds.js";
import HelpSection from "components/HelpSection.js";
import './Home.css'

const CompaniesSlider = Loadable({
    loader: () => import("./components/CompaniesSlider.js"),
    loading: LoaderEmpty,
});

const JobForYou = Loadable({
    loader: () => import("./components/JobForYou"),
    loading: LoaderEmpty,
});

const Reviews = Loadable({
    loader: () => import("./components/Reviews.js"),
    loading: LoaderEmpty,
});

const Home = (props) => {
    const [isBusiness, setIsBusiness] = useState(false);
    const [directProfile, setDirectProfile] = useState(false);

    useEffect(() => {
        const directProfile = JSON.parse(localStorage.getItem("directProfile"));
        if (directProfile) setDirectProfile(directProfile);

        const user = JSON.parse(localStorage.getItem("user"));
        let selected = null;
        if (!user) selected = parseInt(Cookies.get("user_type"));

        const isBusinessUser = (user && user.type === 4) || (selected && selected === 4);
        setIsBusiness(!isBusinessUser);

        document.title = `${window.globalVars.pageTitleBefore}Home${window.globalVars.pageTitleAfter}`;
        window.scrollTo(0, 0);
        window.globalVars.utilities.trackPageview("/");
    }, []);

    return (
        <div className="home-page-gradbay">
            {!isBusiness ? (
                <>
                    <HomeSliderNew type="grad" />
                    <div className="gradbay-sections home_page_new">
                        <JobType type="grad" />
                        <div className="blog-feed">
                            <BlogFeeds type="grads" />
                        </div>
                        <div className="sustainability-wrapper">
                            <div className="container">
                                <Sustainability />
                            </div>
                        </div>
                        <div className="Job-for-you">
                            <h2 className="text-center">Recently added jobs</h2>
                            <div className="container">
                                <div className="row">
                                    <JobForYou history={props.history} />
                                </div>
                            </div>
                        </div>
                        <div className="graduate-review">
                            <Reviews />
                        </div>
                    </div>
                </>
            ) : (
                <div className="business-home-page">
                    <HomeSliderNew type="business" />
                    <div className="gradbay-sections home_page_new">
                        <JobType type="business" />
                        <HelpSection />
                        <div className="blog-feed">
                            <BlogFeeds type="business" />
                        </div>
                        <div className="sustainability-wrapper">
                            <div className="container">
                                <Sustainability />
                            </div>
                        </div>
                        <Statistics />
                        <div className="business-review">
                            <Reviews />
                        </div>
                    </div>
                </div>
            )}
            <div className="companies_slider_wrap">
                <h2>Companies hiring on GradBay</h2>
                <CompaniesSlider />
            </div>
            {directProfile && <UserTypeWelcomePopup />}
        </div>
    );
};

export default Home;