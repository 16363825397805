import TikTokBlogFeeds from 'v2/organisms/career-hub/TikTokBlogFeeds.js';

const BlogFeeds = (props) => {
    return (
        <div className='container text-center'>
            <TikTokBlogFeeds locations="home-page0" userType={props.type} />
        </div>
    );
}

export default BlogFeeds;