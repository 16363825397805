import AuthReducer, { AuthActions } from 'v2/reducers/AuthReducer'
import CoreReducer, { CoreActions } from 'v2/reducers/CoreReducer'
import SearchReducer, { SearchActions } from 'v2/reducers/SearchReducer'
import JobReducer, { JobActions } from 'v2/reducers/JobReducer'
import GradsReducer, { GradsActions } from 'v2/reducers/GradsReducer'
import ReadabilityReducer, { ReadabilityActions } from 'v2/reducers/ReadabilityReducer'

export {
	AuthReducer,
	AuthActions,
	CoreReducer,
	CoreActions,
	SearchReducer,
	SearchActions,
	JobReducer,
	JobActions,
	GradsActions,
	GradsReducer,
	ReadabilityReducer,
	ReadabilityActions
}