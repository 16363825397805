import { Rating } from "react-simple-star-rating";
import './GoogleReviewBar.css'

const GoogleReviewBar = (props) => {

    const handleRedirect = () => {
        const url = 'https://www.google.com/search?q=gradbay&oq=gradbay&gs_lcrp=EgZjaHJvbWUqDAgAECMYJxiABBiKBTIMCAAQIxgnGIAEGIoFMg0IARAuGK8BGMcBGIAEMgYIAhBFGDwyBggDEEUYPDIGCAQQRRhBMgYIBRBFGEEyBggGEEUYPTIGCAcQRRhB0gEIOTIyOWowajSoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x48761ca679d68cad:0xe8e99d1f41a7d624,1,,,,';
        window.open(url, '_blank');
    };

    return (
        <div className="google-review-bar" style={{color: props.themes === 'dark' ? 'black' : 'white'}}>
            <spna className="excellent">Excellent</spna>
            <span style={{cursor: 'pointer'}} onClick={handleRedirect}>
                <Rating className="rating" size={23} initialValue={4.7} readonly/>
            </span>
            <span>{props.footer ? "" : 'reviews on Google'}</span> 
        </div>
    );
};

export default GoogleReviewBar;