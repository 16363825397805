import React, { PureComponent } from "react";
import _ from "lodash";
import Cookies from "js-cookie";
import JobImg from "../images/job.webp";
import GradImg from "../images/grad.webp";
import { Modal } from "reactstrap";
import { Link } from "react-router-dom";
import './Modal.css'

class UserTypeWelcomePopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isBusiness: false,
      fade: false,
      gradaute: false,
      business: false,
      userType: null,
      imagesLoaded: false,
    };
  }

  preloadImages = (imageSources) => {
    const images = [];
    let loadedImages = 0;

    const preloadHandler = () => {
      loadedImages++;
      if (loadedImages === imageSources.length) {
        this.setState({ imagesLoaded: true });
      }
    };

    imageSources.forEach((src) => {
      const img = new Image();
      img.onload = preloadHandler;
      img.src = src;
      images.push(img);
    });
  };

  componentDidMount() {
    this.preloadImages([JobImg, GradImg]);

    const urlSegments = window.location.href.split('/');
    const user = JSON.parse(localStorage.getItem("user"));
    const email = urlSegments[4];
    const token = urlSegments[5];

    if (email && token) {
      this.onSelectAriaOfExpert({
        isBusiness: false,
        user_type: 4,
        isReloadHide: true
      });
      return;
    }

    const selectedUserType = parseInt(Cookies.get("user_type"), 10);
    const isUserTypeBusiness = (user && user.type === 4) || (selectedUserType === 4);

    this.setState({
      modal: user || selectedUserType ? false : true,
      isBusiness: !isUserTypeBusiness
    });

    const currentURL = window.location.href;
    const targetPages = ['profile', 'trynow', 'salary-predictor'];
    const isTargetPage = targetPages.some(page => currentURL.includes(page));

    if (isTargetPage) {
        const userType = Cookies.get("user_type");
        
        if (_.isEmpty(userType)) {
            const expirationMinutes = 300;
            const expirationDate = new Date(Date.now() + expirationMinutes * 60 * 1000);
            Cookies.set("user_type", 3, { expires: expirationDate });

            localStorage.setItem("directProfile", true);
            this.setState({ modal: false });

            window.location.reload();
        }
    }
  }

  onSelectAriaOfExpert = (data) => {
    this.setState({ modal: false, isBusiness: data.isBusiness }, () => {
      var date = new Date();
      var minutes = 300;
      date.setTime(date.getTime() + minutes * 60 * 1000);
      Cookies.set("user_type", data.user_type, { expires: date });
      if(!data.isReloadHide){
        location.reload();
      }
      
    });
  };

  render() {
    if (!this.state.imagesLoaded) {
      return null; // Or you can render a loading indicator
    }
    return (
        <Modal
            isOpen={this.state.modal}
            fade={this.state.fade}
            className="welcome_popup_container"
        >
          <div className="welcome_popup">
            <div className="welcome_popup_main">
            <Link to='/login' className="login-right" onClick={() => this.onSelectAriaOfExpert({ isBusiness: false, user_type: 4, })}>Login</Link>
              <div className="popup_right">
                <h2>Welcome to GradBay</h2>
                <p className="subheading">
                  GradBay is an inclusive hiring platform connecting <br />
                  Generation Z with growing companies.
                </p>
                <ul className="mb-4">
                  <li
                    onClick={() =>  this.onSelectAriaOfExpert({
                        isBusiness: false,
                        user_type: 4,
                      })}
                    className={`${this.state.userType === 4 ? "active" : "not-choosen"} `}
                  >
                    <span className="icon">
                      <img width={300} height={200} loading="lazy" alt="graduate" src={JobImg} />
                    </span>
                    <button >I'm applying</button>
                  </li>
                  <li
                    onClick={() => this.onSelectAriaOfExpert({
                        isBusiness: true,
                        user_type: 3,
                      })}
                    className={`${this.state.userType === 3 ? "active" : "not-choosen"}`}
                  >
                    <span className="icon">
                      <img width={300} height={200} loading="lazy" alt="business" src={GradImg} />
                    </span>
                    <button >I'm hiring</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal>
    );
  }
}

export default UserTypeWelcomePopup;
