import React, { PureComponent } from "react";
import Slider from "react-slick";
import './HomeSlider.css';
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import CharlotteImg from './images/slides/inner/Charlotte.webp';
import JayImg from './images/slides/inner/joy.webp';
import MonikaImg from './images/slides/inner/monika.webp';
import RachaelImg from './images/slides/inner/Rachael.webp';
import Ajimg from './images/slides/inner/aj.webp';
import Cjimg from './images/slides/inner/cj.webp';
import LizzieImg from './images/slides/inner/lizzie.webp';
import HarrietImg from './images/slides/inner/harriet.webp';

class HomeSliderNew extends PureComponent {
  state = {
    user: JSON.parse(localStorage.getItem("user")),
    isBusiness: false,
    counterViews: 0,
    counterApplications: 0,
  };

  componentDidMount() {
    const { user } = this.state;
    const selected = parseInt(Cookies.get("user_type"));
    if ((user && user.type === 3) || (selected && selected === 3)) {
      this.setState({ isBusiness: true }, this.fetchJobStatistics);
    }
  }

  fetchJobStatistics = () => {
    window.globalVars.server.api({
      method: "get",
      url: "api/statistics/jobStatistics",
      then: (res) => {
        if (res.data.success && res.data.data.length > 0) {
          const jobStats = res.data.data.find(item => item.key === 'jobStatistics');
          if (jobStats) {
            const { views, applications, jobs } = jobStats.value;
            this.startCounterIncrementation(Math.floor(views / jobs), Math.floor(applications / jobs));
          } else {
            console.log('Job statistics not found in the response data.');
          }
        } else {
          console.log('No data received or unsuccessful response.');
        }
      },
      catch: (e) => {
        console.log('error ===>>> ', e.message);
      },
    });
  };

  startCounterIncrementation = (finalViews, finalApplications) => {
    const duration = 2000;
    const startTime = performance.now();

    const updateCounter = (timestamp) => {
      const elapsedTime = timestamp - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      this.setState({
        counterViews: Math.max(Math.floor(progress * finalViews), 0),
        counterApplications: Math.max(Math.floor(progress * finalApplications), 0),
      });

      if (progress < 1) {
        requestAnimationFrame(updateCounter);
      }
    };

    requestAnimationFrame(updateCounter);
  };

  renderBannerContent = () => {
    const { isBusiness, counterViews, counterApplications, user } = this.state;
    const startLink = isBusiness ? (user ? "/jobs/post/edit/new" : "/post-free-job") : "/jobs";
    const message = isBusiness
      ? `The average job listed on GradBay gets <span>${counterViews}</span> views and <span>${counterApplications}</span> applications`
      : 'Browse jobs exclusively for graduates.';

    return (
      <div className="banner_content_wrap">
        <h1 className="display_xl">
          {isBusiness ? 'Connecting emerging talent with growing companies.' : 'Connecting growing companies with emerging talent'}
        </h1>
        <p dangerouslySetInnerHTML={{__html: message}} />
        <Link to={startLink} className="banner_slide_btn">Get Started</Link>
      </div>
    );
  };

  renderSlides = () => {
    const { isBusiness } = this.state;
    const slidesData = [
      { businessImg: CharlotteImg, userImg: Cjimg },
      { businessImg: JayImg, userImg: LizzieImg },
      { businessImg: MonikaImg, userImg: HarrietImg },
      { businessImg: RachaelImg, userImg: Ajimg },
    ];

    return slidesData.map(({ businessImg, userImg }, index) => (
      <div className={`home_new_slider ${index === 0 ? 'first_slide' : index === 1 ? 'second_slide' : index === 2 ? 'three_slide' : 'fourth_slide'}`} key={index}>
        <div className="new_slider_wrap">
          {this.renderBannerContent()}
          <figure>
            <img src={isBusiness ? businessImg : userImg} alt="Employer Slide" loading="lazy" width="480" height="450" style={{ objectFit: 'contain' }} />
          </figure>
        </div>
      </div>
    ));
  };

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 600,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="home_new_slider home_new_slider_wrapper">
        <Slider {...settings}>
          {this.renderSlides()}
        </Slider>
      </div>
    );
  }
}

export default HomeSliderNew;
