import Cookies from 'js-cookie'
import ReactGA4 from "react-ga4"

class Utilities {

    GAInitialized = false;

    getFromCache = (id) => {
        let data = localStorage.getItem(id + '-cache');
        if (data) {
            try {
                data = JSON.parse(data);
            } catch (e) {
                console.error(e);
                return null;
            }

        }
        return data;
    }

    getViewHistory = (type) => {

        let user = localStorage.getItem("user");

        if (!user) {
            return [];
        }

        user = JSON.parse(user);

        if (!user.viewHistory) {
            return [];
        }

        let viewHistory = user.viewHistory.filter(item => item.category === type).reverse();
        let ids = [];

        for (let i = 0; i < viewHistory.length; i++) {
            let id = viewHistory[i].bullhornId;
            if (ids.indexOf(id) === -1) {
                ids.push(id);
            }
        }

        return ids;
 
    }

    randomStr = (length, all) => {
        let text = "";
        let possible = "abcdefghijklmnopqrstuvwxyz";
        if (all) possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    }

    saveToCache = (id, data) => {
        localStorage.setItem(id + '-cache', JSON.stringify(data));
    }

    timeSince = (date) => {

        let seconds = Math.floor((new Date() - new Date(date)) / 1000);

        let interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " years ago";
        }

        interval = Math.floor(seconds / 2592000);

        if (interval > 1) {
            return interval + " months ago";
        }

        interval = Math.floor(seconds / 86400);

        if (interval > 1) {
            return interval + " days ago";
        }

        interval = Math.floor(seconds / 3600);

        if (interval > 1) {
            return interval + " hours ago";
        }

        interval = Math.floor(seconds / 60);

        if (interval > 1) {
            return interval + " mins ago";
        }

        return "just now";

    }

    capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    parseUrl = (url) => {
        let urlParts = url.split('/');
        urlParts.splice(0, 3);
        return urlParts
    }

    ordinalSuffix = (i) => {

        let j = i % 10,
            k = i % 100;

        if (j === 1 && k !== 11) {
            return i + "st";
        }

        if (j === 2 && k !== 12) {
            return i + "nd";
        }

        if (j === 3 && k !== 13) {
            return i + "rd";
        }

        return i + "th";

    }

    initTracking = () => {
        if (window.location.hostname.indexOf("gradbay.com") !== -1) {
            ReactGA4.initialize("G-T1H0VL28GZ")
        } else {
            ReactGA4.initialize("G-Q7P4J2MP9G")
        }

        let user = JSON.parse(localStorage.getItem("user"));

        if ( (user !== null) && (user !== undefined) ) {

            if (user.type === 3) {
                window.amplitude.setUserId(user.bullhornContactId)
            } else if (user.type === 4) {
                window.amplitude.setUserId(user.bullhornId)
            } else {
                window.amplitude.setUserId("a")
            }

            window.amplitude.setUserProperties({
                'email': user.email
            })

        } else {

        }

        this.GAInitialized = true;

    }

    trackEvent = (category, action, strProps, objProps) => {

        if (!this.GAInitialized) {
            this.initTracking();
        }

        let gaEvent = {
            category: category,
            action: action,
        }

        if (strProps !== undefined) {
            gaEvent.label = strProps.toString();
        }

        objProps.category = category;

        ReactGA4.event(gaEvent)
        window.amplitude.logEvent(action, objProps);

    }

    trackPageview = (url) => {

        if (!this.GAInitialized) {
            this.initTracking();
        }

        ReactGA4.send({ hitType: "pageview", page: '/' + url })
    }

    isENU = (field) => {

        if ( (field === undefined) || (field === null) || (field === "") ) {
            return true;
        } else {
            return false;
        }

    }

    isNU = (field) => {

        if ( (field === undefined) || (field === null) ) {
            return true;
        } else {
            return false;
        }

    }

    htmlToText = (html) => {
        let div = document.createElement("div");
        div.innerHTML = html;
        return (div.textContent || div.innerText || "");
    }

    getBase64 = (file) => {

        return new Promise(function(resolve, reject) {

            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = function () {
                const base64String = reader.result.replace(/\s/g, '');
                resolve(base64String);
            };

            reader.onerror = function (error) {
                reject(error)
            };

        })

    }

    dayDifference = (date1, date2) => {
        return Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
    }

    replaceAll = (str, search, replacement) => {
        return str.replace(new RegExp(search, 'g'), replacement);
    };

    dataURLtoFile = (dataurl, filename) => {

        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});

    }

    generateRandomStr = (length) => {

    	var text = "";
    	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    	for (var i = 0; i < length; i++)
    		text += possible.charAt(Math.floor(Math.random() * possible.length));

    	return text;

    }

    shorten = (str, maxLen, separator = ' ') => {
        if (str.length <= maxLen) return str;
        return str.substr(0, str.lastIndexOf(separator, maxLen));
    }

    shortenToChar = (str, maxLen) => {
        if (str.length <= maxLen) return str;
        return str.substr(0, maxLen) + "..";
    }

    formatDate = (dateObj, format) => {

    	var str = new Date();

    	if (dateObj !== undefined) {
    		str = new Date(dateObj);
    	}

        if ( (typeof dateObj === 'string') || (typeof dateObj === 'number') ) {
            str = new Date(dateObj * 1000);
        }

    	var dateStr = "";
    	var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    	var months;
    	var days;
    	var years;
    	var hours;
    	var minutes;

    	switch (format) {

            case "MM/YYYY":
                months = (str.getMonth()+1);
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                dateStr = months + "/" + years;
    			break;

    		case "DD/MM/YYYY":
    			months = (str.getMonth()+1);
    			days = (str.getDate());
    			years = str.getFullYear();
    			if (months < 10) months = "0" + months
    			if (days < 10) days = "0" + days
    			dateStr = days + "/" + months + "/" + years;
    			break;

            case "DD/MM":
    			months = (str.getMonth()+1);
    			days = (str.getDate());
    			if (months < 10) months = "0" + months
    			if (days < 10) days = "0" + days
    			dateStr = days + "/" + months;
    			break;

            case "YYYY-MM-DD":
    			months = (str.getMonth()+1);
    			days = (str.getDate());
    			years = str.getFullYear();
    			if (months < 10) months = "0" + months
    			if (days < 10) days = "0" + days
    			dateStr = years + "-" + months + "-" + days;
    			break;

            case "HH:MM":
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                dateStr = hours + ":" + minutes;
                break;

            case "mm DD":
                months = monthNames[(str.getMonth())];
                days = (str.getDate());
                if (days < 10) days = "0" + days
                dateStr = months + " " + days;
                break;

    		default:
    			months = (str.getMonth()+1);
    			if (months < 10) months = "0" + months
    			years = str.getFullYear();
    			if (years < 10) years = "0" + years
    			days = (str.getDate());
    			if (days < 10) days = "0" + days
    			hours = str.getHours()
    			if (hours < 10) hours = "0" + hours
    			minutes = str.getMinutes()
    			if (minutes < 10) minutes = "0" + minutes
    			dateStr = years + "-" + months + "-" + days + " " + hours + ":" + minutes
    			break;

    	}

    	return dateStr;

    }

    JSONparse = (data) => {
        if(data){
            let replace = /&quot;/gi;
            let replase2 = /&shy;/gi;
            let valid_data = data.replace(replace, '"').replace(replase2, '').replace(/­\n/g, '').replace(/­­­­­-/g, '')
            let json_data = JSON.parse(valid_data.replace(/(\r\n|\n|\r)/gm, ""))
            return json_data;
        }else{
            return
        }
    }

    getSelectedUserType = () => {
        let selectedUserType =  parseInt(Cookies.get("user_type"))
        return selectedUserType
    }
    
    toTitleCase = (text) => {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
}

export default Utilities;
