import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import BlogSlide from "./BlogSlide";
import { apiRequest, sleep } from "v2/Utilities";
import { uniqueId } from "lodash";

const TikTokBlogFeeds = (props) => {
  const [posts, setPosts] = useState([])

  const getBlogPosts = async () => {
    try {
      const response = await apiRequest({
        method: 'GET',
        url: `career-hub/list/get?location=${props.locations}&userType=${props.userType}`
      })
      if (response.data) {
        let data = response.data.data;
        let posts = data?.posts[0]?.data || []
        let latestCareerAdvice = data?.posts[1]?.data || []
        let trainingAndUpskilling = data?.posts[2]?.data || []

        let final = [...posts, ...latestCareerAdvice, ...trainingAndUpskilling]

        setPosts(final)
      }
    } catch (error) {
      console.log('careerHub error ===>>>> ', error)
    }
  }

  useEffect(() => {
    getBlogPosts()
  }, [])

  let settings = {
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    infinite: posts?.length > 2,
    nextArrow: <BsChevronRight color={"#858585"} />,
    prevArrow: <BsChevronLeft color={"#858585"} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <StyleTikTokFeedWrap>
        {posts.length ? <h2 className={`${props.locations === 'home-page0' && 'home-feed'}`} style={{ textAlign: props.locations !== 'home-page0' && "left" }}>Resources</h2> : props.isShowMessage && <h2 style={{ textAlign: props.locations !== 'home-page0' && "left" }}>Resources</h2>}
        <div className="feed_blog_wrap">
          <Slider {...settings}>
            {posts ?
              (posts?.map((item, i) => {
                if (item.contentType === 'Article') {
                  return <BlogSlide
                    key={uniqueId}
                    blogImage={item.coverImage ? window.globalVars.bucket + window.globalVars.bucketCareerHubCoverDir + item.coverImage : `https://gradbay.s3.eu-west-2.amazonaws.com/small-logo.png`}
                    blogTitle={item.title}
                    blogContent={item.description}
                    blogUrl={`/career-hub/post/` + item.slug}
                  />
                } else {
                  return <iframe src={`https://www.tiktok.com/embed/v2/${item.tiktokUrl}`} height="742" title="tiktok"></iframe>
                }
              }))
              : props.isShowMessage && <div>Data not found.</div>
            }
          </Slider>
        </div>
      </StyleTikTokFeedWrap>
    </>
  );
};

export default TikTokBlogFeeds;

const StyleTikTokFeedWrap = styled.div`
  .home-feed{
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .feed_blog_wrap {
    .slick-slide {
      & > div {
        display: flex;
        justify-content: center;
      }
    }
    .slick-list {
      margin: 0 -30px;
      iframe{
        display: inline-block;
        min-width: 325px;
        width: auto !important;
        border: 1px solid #d3d3d3;
        border-radius: 12px;
        background: transparent;
        height: 740px;
        margin-top: 0px;
        overflow: hidden;
      }
    }
    .slick-arrow {
      width: 45px;
      height: 45px;
      background: #fff;
      border-radius: 50%;
      padding: 11px;
      z-index: 11;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
      path{
        fill:#999494;
      }
      &.slick-prev{
        transform: rotate(0deg);
        top: calc(50% - 23px);
      }
    }
  }
`;
