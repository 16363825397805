import styled from "styled-components";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import utilities from "components/Utilities";
import sanitizeHtml from "sanitize-html";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
let Utilities = new utilities();

const BlogSlide = (props) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keywordFromURL = searchParams.get('keyword');

  const highlightText = (text, query) => {
    if (!query || typeof query !== 'string') return text;
  
    const regex = new RegExp(`(${query})`, 'gi');
    const highlightedText = text.replace(regex, '<b class="highlighted">$1</b>');
  
    if (highlightedText.indexOf('<b class="highlighted">') > 370) {
      return highlightedText.substring(0, 370) + ' <b class="highlighted">...</b>';
    } else {
      return highlightedText;
    }
  };
  
  let html = props.blogContent
  const blogContent = sanitizeHtml(html, {
    allowedTags: ["b", "i", "em", "strong", "a", "p", "div", "span"]
  });
  
  const highlightedTitle = highlightText(props.blogTitle, keywordFromURL);
  const highlightedDescription = highlightText(blogContent, keywordFromURL);

  return (
    <BlogSlideStyle className="slide_items">
      <div className="inner_content">
        <figure>
          <img src={props.blogImage} alt="blogImage" loading="lazy" />
        </figure>
        <div className="content_blog">
          <h3 className="card_title" dangerouslySetInnerHTML={{
            __html: Utilities.shorten(Utilities.htmlToText(highlightedTitle)).length > 60
              ? `${highlightedTitle.substr(0, 60)} ...`
              : highlightedTitle,
          }} />
          <p dangerouslySetInnerHTML={{ __html: Utilities.shorten(Utilities.htmlToText(highlightedDescription)).length > 370 ? `${highlightedDescription.substr(0, 370)} ...` : highlightedDescription }} />
        </div>
        <Link className="cta_btn" to={props.blogUrl}>
          Continue Reading <HiOutlineChevronDoubleRight />
        </Link>
      </div>
    </BlogSlideStyle>
  );
};

export default BlogSlide;

const BlogSlideStyle = styled.div`
  &.slide_items {
    background: #fff;
    text-align: center;
    border-radius: 8px;
    max-width: 325px;
    overflow: hidden;
    border: 1px solid #d3d3d3;
    width:100%;
    .inner_content {
      position: relative;
      min-height: 735px;
      figure {
        margin: 0;
        img {
          height: 300px;
          max-width: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
    .cta_btn {
      position: absolute;
      bottom: 15px;
      right: 15px;
      cursor: pointer;
      font-size: 13px;
      color: #bd99ff;
      font-weight: 500;
    }
    .content_blog {
      min-height: auto;
      max-height: 385px;
      overflow-y: auto;
      padding: 15px;
      text-align: left;
      padding-bottom: 40px;
      position: relative;

      .highlighted {
        background-color: yellow;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.7);
        line-height: 23px;
        margin-bottom: 10px;
      }
      .card_title {
        font-size: 16px;
        min-height: 40px;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 65px;
        margin-bottom: 15px;
        line-height: 24px;
        display: block;
        align-items: center;
      }
    }
  }
`;
