import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Footer.css';
import GoogleReviewBar from './GoogleReviewBar';
import ClimateLogo from '../images/climate_logo.svg'


class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
    }

    handleChange = (name, value) => {
        let state = this.state;
        state[name] = value;
        this.setState(state);
    }

    signupForNewsletter() {
    }

    render() {

        return (
            <div className='footer'>
                <div className="container footer-container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="footer-links">
                                        <ul className="footer-dot-ul">
                                            <li>
                                                <Link to="/about">About Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact-us">Contact Us</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-links">
                                        <ul className="footer-dot-ul">
                                            <li>
                                                <Link to="/privacy">Privacy Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="/faq">FAQs</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-links">
                                        <ul className="footer-dot-ul">
                                            <li>
                                                <Link to="/terms/employers">T&C (Employers)</Link>
                                            </li>
                                            <li>
                                                <Link to="/terms/graduates">T&C (Graduates)</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-links">
                                        <ul className="footer-dot-ul">
                                            <div className='sustainability-footer'>
                                                <a className="nav-link" href="https://climate.stripe.com/UPtogx" target="_blank" >
                                                    <img width={40} height={40} src={ClimateLogo} alt='sustainability' />
                                                    Sustainability
                                                </a>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <div className="container">
                        <div className="copy-right">
                            <p className="rights">© 2024, <Link to="/">GradBay </Link> | All rights reserved.</p>
                            <div>
                                <GoogleReviewBar footer={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

export default Footer;
