import { useDispatch } from 'react-redux'
import { apiRequest } from 'v2/Utilities'
import { SearchActions } from 'v2/reducers/SearchReducer'
import { CoreActions } from 'v2/reducers'
import { change } from 'redux-form';
import _ from 'lodash'

const useAuth = (data) => {
    const dispatch = useDispatch()

    const setJobSearchParams = async (payload) => {
        try {
            dispatch(SearchActions.setJobSearchParams(payload))
        } catch (error) {
            console.log('setJobSearchParams', error)
        }
    }

    const setGradSearchParams = async (payload) => {
        try {
            dispatch(SearchActions.setGradSearchParams(payload))
        } catch (error) {
            console.log('setGradSearchParams', error)
        }
    }

    const removeGradSearchParams = async (payload, key) => {
        try {
            dispatch(SearchActions.removeGradSearchParams({ payload: payload, key: key }))
        } catch (error) {
            console.log('removeGradSearchParams', error)
        }
    }

    const clearAdvanceSearchGrad = async (payload, key) => {
        try {
            dispatch(SearchActions.clearAdvanceSearchGrad({ payload: payload, key: key }))
        } catch (error) {
            console.log('clearAdvanceSearch', error)
        }
    }

    const searchAllGrad = async (payload, key) => {
        try {
            dispatch(SearchActions.searchAllGrad({ payload: payload, key: key }))
        } catch (error) {
            console.log('searchAllGrad', error)
        }
    }
    const searchAllJob = async (payload, key) => {
        try {
            dispatch(SearchActions.searchAllJob({ payload: payload, key: key }))
        } catch (error) {
            console.log('searchAllJob', error)
        }
    }


    const removeJobSearchParams = async (payload, key) => {
        try {
            dispatch(SearchActions.removeJobSearchParams({ payload: payload, key: key }))
        } catch (error) {
            console.log('removeJobSearchParams', error)
        }
    }

    const clearFilterJob = async (payload, key) => {
        try {
            dispatch(SearchActions.clearFilterJob({ payload: payload, key: key }))
        } catch (error) {
            console.log('clearFilterJob', error)
        }
    }
    const clearFilterGrad = async (payload, key) => {
        try {
            dispatch(SearchActions.clearFilterGrad({ payload: payload, key: key }))
        } catch (error) {
            console.log('clearFilterJob', error)
        }
    }

    const getSonicJobCategories = async (payload) => {
        try {
            const loaderName = `sonic_categories_loader`
            dispatch(CoreActions.loaderActivate(loaderName))
            const response = await apiRequest({
                method: 'get',
                url: `sonic-vacancy/get/categories`,
                params: payload
            })

            dispatch(SearchActions.setSonicJobCategories(response.data.data))
            dispatch(CoreActions.loaderDeactivate(loaderName))
        } catch (error) {
            console.log('setGradSearchParams', error)
        }
    }

    const getSonicJobCities = async (keyword, page) => {
        try {
            const loaderName = `sonic_cities_loader`
            dispatch(CoreActions.loaderActivate(loaderName))
            const response = await apiRequest({
                method: 'get',
                url: `sonic-vacancy/get/cities`,
                params: { keyword, page }
            })
            dispatch(SearchActions.setSonicJobCities(response.data.data))
            dispatch(CoreActions.loaderDeactivate(loaderName))
        } catch (error) {
            console.error('Error in getSonicJobCities:', error)
        }
    }

    const getHotJobCategories = async (payload) => {
        try {
            const loaderName = `hot_categories_loader`
            dispatch(CoreActions.loaderActivate(loaderName))
            const response = await apiRequest({
                method: 'get',
                url: `hot-vacancy/get/categories`,
                params: payload
            })

            dispatch(SearchActions.setHotJobCategories(response.data.data))
            dispatch(CoreActions.loaderDeactivate(loaderName))
        } catch (error) {
            console.log('setGradSearchParams', error)
        }
    }

    const getHotJobCities = async (payload) => {
        try {
            const loaderName = `hot_cities_loader`
            dispatch(CoreActions.loaderActivate(loaderName))
            const response = await apiRequest({
                method: 'get',
                url: `hot-vacancy/get/cities`,
                params: payload
            })
            dispatch(SearchActions.setHotJobCities(response.data.data))
            dispatch(CoreActions.loaderDeactivate(loaderName))
        } catch (error) {
            console.log('setGradSearchParams', error)
        }
    }

    const clearAllCatAndCity = () => {
        dispatch(SearchActions.clearAllCatAndCity())
    }

    const clearAllHotCatAndCity = () => {
        dispatch(SearchActions.clearAllHotCatAndCity())
    }

    const setFormFieldValues = (newFormValues) => {
        for (const [key, value] of Object.entries(newFormValues)) {
            dispatch(change("job_search", key, value));
        }
    }

    const clearFilters = (formValues) => {
        Object.keys(formValues).forEach((key) => {
            if (key === 'jobtype') return
            dispatch(change("job_search", key, ""));
        });
    };

    const clearFilter = (filter_key) => {
        if (filter_key === "sonic_salary_unit") {
            dispatch(change("job_search", 'sonic_salary_unit', ""));
        }else if (filter_key === "job_source") {
            dispatch(change("job_search", 'job_source', ""));
        } else if (!_.isEmpty(filter_key)) {
            dispatch(change("job_search", filter_key, []));
        }
    };

    return {
        setJobSearchParams,
        setGradSearchParams,
        removeGradSearchParams,
        removeJobSearchParams,
        clearAdvanceSearchGrad,
        searchAllGrad,
        searchAllJob,
        clearFilterJob,
        clearFilterGrad,
        getSonicJobCategories,
        getSonicJobCities,
        clearAllCatAndCity,
        getHotJobCategories,
        getHotJobCities,
        clearAllHotCatAndCity,
        setFormFieldValues,
        clearFilters,
        clearFilter
    }
};




export default useAuth;
