import { createBrowserHistory } from 'history';
import axios from "axios";
import { stopSubmit, startSubmit, reset } from 'redux-form';
import { store } from "v2/Store";
import _ from 'lodash'
import { ToastStore } from 'react-toasts';
import { CoreActions } from 'v2/reducers/CoreReducer'
import { AuthActions } from 'v2/reducers/AuthReducer'
import ExpressJobsImg from "../images/job-type/express-job.png";
import moment from "moment";

export const history = createBrowserHistory();

export const forwardTo = (path) => {
    history.push(path)
}

export const apiRequest = async (args = {}) => {
    let token = localStorage.getItem('authToken')
    let headers = {}
    if (token) {
        headers = {
            'Authorization': `Token ${token || ''}`,
        }
    }
    headers = {
        ...headers,
        'x-api-key': '3AtOnGcAg6mesqucN8AiO28kgZhTqoDR'
    }
    args.url = `api/${args.url}`
    return axios({
        ...args,
        headers
    })
}

export const formSubmitStart = (formName) => {
    store.dispatch(startSubmit(formName))
}

export const formSubmitError = (formName, error) => {
    error.response ? error.response.data.data : error.message
    if (!_.isEmpty(error) && !_.isEmpty(error.response)) {
        store.dispatch(stopSubmit(formName))
        if (!_.isEmpty(error.response.data.data)) {
            store.dispatch(stopSubmit(formName, error.response.data.data))
        } else {
            ToastStore.error(error.response.data.message)
        }
    } else {
        ToastStore.error(error.message)
    }
}

export const formSubmitSuccess = (formName, message) => {
    store.dispatch(reset(formName))
    ToastStore.success(message)
}

export const requestStart = (loaderName) => {
    store.dispatch(CoreActions.loaderActivate(loaderName))
}

export const requestError = (loaderName, message) => {
    store.dispatch(CoreActions.loaderDeactivate(loaderName))
    if (message) ToastStore.error(message)
}

export const requestSuccess = (loaderName, message) => {
    store.dispatch(CoreActions.loaderDeactivate(loaderName))
    if (message) ToastStore.success(message)
}

export const showToast = (message, type) => {
    if (type === 'error') ToastStore.error(message)
    if (type === 'success') ToastStore.success(message)
}

const getQueryParams = () => {
    var url = window.location.href;
    var vars = {};
    var hashes = url && url.split("?")[1];
    var hash = hashes && hashes.split('&');

    if (hash) {
        for (var i = 0; i < hash.length; i++) {
            let params = hash[i].split("=");
            vars[params[0]] = params[1];
        }
    }
    return vars;
}

export const createJobUrl = (job, jobtype = "express") => {
    let url;
    if (jobtype === 'express' || jobtype === 'hotjob') {
        url = `/jobs/${jobtype}/${job?.id}`;
        url += job?.location ? `?location=${job?.location}` : '';
        url += job?.category ? `${job?.location ? '&' : '?'}category=${job?.category}` : '';
    }

    return url;
};

export const trackSource = (url) => {
    const queryParams = new URLSearchParams(url);
    const utmSourceParam = queryParams.get("utm_source");
    if (utmSourceParam) {
        window.globalVars.utilities.trackEvent(utmSourceParam, utmSourceParam, utmSourceParam, {});
    }
};

export const capitalizeFirstLetter = (str) => {
    if (!str) return
    return str.split(' ').map(word =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const loginOrSignupSuccess = (formName, { data }, location, free_job_post) => {
    localStorage.setItem('authToken', data.user.token);
    localStorage.setItem("user", JSON.stringify(data.user));
    store.dispatch(AuthActions.setAuth(data))
    store.dispatch(reset(formName))
    ToastStore.success(data.message || 'Success')
    setTimeout(() => {

        if (location && location?.state?.from?.pathname) {
            window.location.href = location.state.from.pathname
            return false
        }

        let query = getQueryParams()
        if (query.job_id !== undefined) {
            window.location.href = `/jobs/post/${query.job_id}`
        } else if (free_job_post !== undefined && free_job_post === 'free-job-post') {
            window.location.href = '/jobs/post/edit/new'
        } else {
            if (data.user.type === 4) {
                if (data.onboardingCompleted) {
                    window.location.href = "/dashboard"
                } else {
                    window.location.href = "/graduate/onboarding"
                }
            } else if (data.user.type === 3) {
                const urlParams = new URLSearchParams(window.location.search);
                const redirectPath = urlParams.get('redirect');
                if (redirectPath === 'job-ad-writer' || redirectPath === 'marketing-ad-writer') {
                    window.location.href = "/jobs/post/edit/new"
                } else if (data.onboardingCompleted) {
                    window.location.href = "/my-profile/business"
                } else {
                    window.location.href = "/business/onboarding"
                }
            } else {
                window.location.href = "/"
            }
        }
    }, 100)
}

export const fileObjToBase64 = (file) => {
    return new Promise(function (resolve, reject) {
        if (!file) reject(false)
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            resolve(reader.result);
        }
    })
}

export const JSONparse = (data) => {
    if (data) {
        let replace = /&quot;/gi;
        let replase2 = /&shy;/gi;
        let valid_data = data.replace(replace, '"').replace(replase2, '').replace(/­\n/g, '').replace(/­­­­­-/g, '')
        let json_data = JSON.parse(valid_data.replace(/(\r\n|\n|\r)/gm, ""))
        return json_data;
    } else {
        return
    }
}

export function isEmpty(value) {
    return !_.trim(value) ? true : false
}

export function isEmail(value) {
    return value && /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value.trim()) ? true : false
}

export function isNumber(value) {
    return value && /^[0-9]*$/i.test(value) ? true : false
}

export function isChar(value) {
    return value && value.match(/^[a-zA-Z ]+(\s{0,1}[a-zA-Z ])*$/) ? true : false;
}

export function isAlphanumeric(value) {
    return value && value.match(/^[0-9a-zA-Z,]+(\s{0,1}[0-9a-zA-Z, ])*$/) ? true : false;
}

export function isLowerCase(value) {
    return value && /[a-z]/.test(value) ? true : false;
}

export function isUpperCase(value) {
    return value && /[A-Z]/.test(value) ? true : false;
}

export function isBlankSpace(value) {
    return value && / /.test(value) ? true : false;
}

export function minLenght(value, length) {
    return value && value.length < length ? true : false;
}

export function maxLenght(value, length) {
    return value && value.length > length ? true : false;
}

export function minMaxLenght(value, min_lenght, max_lenght) {
    return value && (value.length < min_lenght || value.length > max_lenght) ? true : false;
}

export function isPassUpperLowerNumAndSpecialChar() {
    return value && value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$/) ? true : false
}

export function isAlphanumericWithSpace(value) {
    return value && !value.match(/^[a-zA-Z0-9 ]*$/) ? true : false
}

export function isEmoji(value) {
    return value && value.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/) ? true : false
}

export const groupBy = (data) => {
    let groupedData = _.chain(data).groupBy("type").map((value, key) => ({ type: key, tags: value })).value();
    return groupedData
}


export const getProfileProgress = (cachedUser) => {
    if (cachedUser) {
        let show = true;
        let progress = [
            {
                name: "Media",
                completed: false,
                link: "/graduate/onboarding/1",
            },
            {
                name: "Education & Experience",
                completed: false,
                link: "/graduate/onboarding/2",
            },
            {
                name: "Skills",
                completed: false,
                link: "/graduate/onboarding/3",
            },
            {
                name: "Job Preferences",
                completed: false,
                link: "/graduate/onboarding/4",
            },
        ];

        if (cachedUser.customTextBlock6) {

            let videos = window.globalVars.utilities.JSONparse(cachedUser.customTextBlock6);

            if (!_.isEmpty(videos)) {
                progress[0].completed = true;
            }
        }

        if (
            !cachedUser.educationHistory ||
            cachedUser.educationHistory.length !== 0
        ) {
            progress[1].completed = true;
        }

        let tags = JSON.parse(localStorage.getItem("tags"));

        if (tags) {
            tags = tags.candidateTags;

            let categorizedTags = {};

            tags.map((item) => {
                if (categorizedTags[item.cat] === undefined) {
                    categorizedTags[item.cat] = [];
                }
                item.text = item.text.split(" - ")[0];
                categorizedTags[item.cat].push(item.text);
                return item;
            });

            if (
                !window.globalVars.utilities.isENU(cachedUser.customTextBlock3) &&
                cachedUser.customTextBlock3.length > 0
            ) {
                let i = 0;

                while (i < cachedUser.customTextBlock3.length) {
                    let tag = cachedUser.customTextBlock3[i];

                    for (let key in categorizedTags) {
                        if (categorizedTags[key].indexOf(tag.split(" - ")[0]) > -1) {
                            if (key === "G Skills" || key === "G Languages") {
                                progress[2].completed = true;
                            } else {
                                progress[3].completed = true;
                            }
                        }
                    }
                    i++;
                }
            }
        }

        show = true;

        let completedSections = 0;

        for (let i = 0; i < progress.length; i++) {
            if (progress[i].completed) completedSections++;
        }

        return {
            show: show,
            progress: progress,
            completeness: Math.round((completedSections / 4) * 100),
        }
    }
};

export const addTargetBlankToLinks = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const links = div.getElementsByTagName('a');
    for (let link of links) {
        link.setAttribute('target', '_blank');
    }
    return div.innerHTML;
};

export const isConditionMet = (condition, answers) => {
    if (!condition) return true;
    const { questionId, value } = condition;
    return answers[questionId] === value;
};

const checkIsAllowed = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const user = JSON.parse(localStorage.getItem('user'));
    const adminToken = localStorage.getItem('adminToken')
    if ((user && (user.type === 6 || user.type === 2)) || ['Europe/London', 'Europe/Dublin', 'Europe/Belfast', 'Europe/Edinburgh'].includes(timezone) || adminToken) {
        return false
    } else {
        return true
    }
}

const getFrequency = (frequency) => {
    switch (frequency) {
        case "daily":
            return "day";
        case "weekly":
            return "week";
        case "monthly":
            return "month";
        default:
            return 0;
    }
};

export const checkApplicationLimit = (data) => {
    if (checkIsAllowed()) {
        return {
            allowed: false,
            message: "We can only accept applications from within the UK"
        };
    }

    if (data?.job?.used >= data?.job?.total) {
        return {
            allowed: false,
            jobLimitSucceed: true,
            message: `This job has reached its ${data?.job?.frequency} application limit. Please try again at the beginning of the next ${getFrequency(data?.job?.frequency)}.`
        };
    }

    if ((data?.jobsCPA?.used >= data?.jobsCPA?.total)) {
        return {
            allowed: false,
            message: `This job has reached its ${data?.jobsCPA.frequency} application limit. Please try again at the beginning of the next ${getFrequency(data?.jobsCPA.frequency)}.`
        }
    }

    if (data?.companyCPA.used >= data?.companyCPA?.total) {
        return {
            allowed: false,
            message: `This company has reached its ${data?.company?.frequency} application limit. Please try again at the beginning of the next ${getFrequency(data?.companyCPA?.frequency)}.`
        }
    }

    if (data?.company?.used >= data?.company?.total) {
        return {
            allowed: false,
            message: `This company has reached its ${data?.company?.frequency} application limit. Please try again at the beginning of the next ${getFrequency(data?.company?.frequency)}.`
        };
    }

    if (data?.title?.used >= data?.title?.total) {
        return {
            allowed: false,
            message: `You have applied similarly job.`
        };
    }

    if (data?.user?.used >= data?.user?.total) {
        return {
            allowed: false,
            message: `You've already applied for another job at this company. Please try again at the beginning of the next ${getFrequency(data?.user?.frequency)}.`
        };
    }

    return { allowed: true, message: `` };
};


export const formatDate = (date, format = "YYYY-MM-DD") => {
    return moment(date).format(format);
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export const extractJobDetails = (vacancy) => {
    return {
        id: vacancy.id,
        title: vacancy.title,
        logo: ExpressJobsImg,
        salary: vacancy.salary_details?.salary_min?.split("per")?.[0] || "N/A",
        salaryRate: vacancy?.salary_details?.period ? `Per ${vacancy?.salary_details?.period}` : "-",
        subTitle: vacancy.subTitle,
        location: vacancy.location,
        jobDescription: vacancy.description,
        url: vacancy.url,
        category: vacancy.category,
        state: vacancy.state,
        questions: vacancy.interview_json && JSONparse(vacancy.interview_json),
        companyName: vacancy.company,
        isJobApplied: vacancy.isJobApplied,
        limit: vacancy.limit
    };
};

export const generateJobPostingSchema = (jobDetails, vacancy) => {
    return {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        title: jobDetails.title,
        description: jobDetails.jobDescription,
        datePosted: formatDate(vacancy.dateposted),
        validThrough: formatDate(vacancy.expirationdate),
        employmentType: jobDetails.subTitle,
        hiringOrganization: {
            "@type": "Organization",
            name: vacancy.company,
            logo: "https://gradbay.s3.eu-west-2.amazonaws.com/express-job.png",
        },
        jobLocation: {
            "@type": "Place",
            address: {
                "@type": "PostalAddress",
                addressLocality: jobDetails.location || "Not given",
                addressRegion: vacancy.state || "N/A",
                addressCountry: "GB",
                postalCode: vacancy["postal-code"],
                streetAddress: vacancy.streetaddress || "N/A",
            },
        },
        baseSalary: {
            "@type": "MonetaryAmount",
            currency: "GBP",
            value: {
                "@type": "QuantitativeValue",
                value: jobDetails.salary,
                unitText: jobDetails.salaryRate,
            },
        },
        url: window.location.href,
    };
};

export const getSalaryRange = (sonic_salary_unit) => {
    let minSalary, maxSalary;

    switch (sonic_salary_unit) {
        case "annum":
            minSalary = 50;
            maxSalary = 200000;
            break;
        case "day":
            minSalary = 10;
            maxSalary = 8000;
            break;
        case "hour":
            minSalary = 5;
            maxSalary = 1000;
            break;
        default:
            minSalary = "";
            maxSalary = "";
            break;
    }

    return { minSalary, maxSalary };
};

export const getFilterCounts = (formValues) => {
    let count = 0;
    for (const key in formValues) {
        if (key !== "jobtype" && key !== "sonic_salary") {
            const value = formValues[key];
            if (Array.isArray(value) && value.length > 0) {
                count += value.length;
            } else if (_.isString(value) && !_.isEmpty(value)) {
                count++;
            }
        }
    }
    return count;
}

export const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

export const setMetaTitle = (title) => {
    document.title = window.globalVars.pageTitleBefore + title + window.globalVars.pageTitleAfter;
}

export const isJobExpired = (expirationDate) => {
    let currentDate = new Date();
    let jobExpiration = new Date(expirationDate);
    return currentDate > jobExpiration;
}

export const sonicJobDetailsFormat = (vacancy) => {
    const jobDetails = {
        id: vacancy.id,
        title: vacancy.title,
        logo: vacancy.logo || ExpressJobsImg,
        salary: vacancy.jobSource === 'adzuna' ? vacancy?.customSalary?.min ? `£${vacancy?.customSalary?.min.toLocaleString()} - £${vacancy?.customSalary?.max.toLocaleString()}` : vacancy?.salary || 'Not disclosed' : vacancy.salary_details?.salary_min?.split('per')?.[0] || "Not Disclosed",
        salaryRate: vacancy.jobSource === 'adzuna' ? vacancy.salary?.split('/')?.[1] ? `Per ${vacancy.salary?.split('/')?.[1]}` : '-' : vacancy?.salary_details?.period ? `Per ${vacancy?.salary_details?.period}` : "-",
        subTitle: vacancy.subTitle,
        location: vacancy.location,
        jobDescription: vacancy.description,
        url: vacancy.url,
        category: vacancy.category,
        state: vacancy.state,
        questions: vacancy.interview_json && JSONparse(vacancy.interview_json),
        companyName: vacancy.company,
        limit: vacancy.limit,
        appliedApplications: vacancy.appliedApplications,
        isJobApplied: vacancy.isJobApplied,
        jobSource: vacancy.jobSource,
        cpa: vacancy.cpa
    }
    return jobDetails
}

export const setSonicJobApplicationJSONLD = (jobDetails) => {
    if (window.location.href.indexOf("gradbay.us") === -1) return

    const existingScript = document.querySelector('script[type="application/ld+json"]');
    if (existingScript) {
        existingScript.remove();
    }

    let datePostedStr = moment(jobDetails.dateposted).format("YYYY-MM-DD");
    let validTillStr = moment(jobDetails.expirationdate).format("YYYY-MM-DD");

    const jobPosting = {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        "title": `${jobDetails.title}`,
        "description": `${jobDetails.jobDescription}`,
        "datePosted": `${datePostedStr}`,
        "validThrough": `${validTillStr}`,
        "employmentType": `${jobDetails.subTitle}`,
        "hiringOrganization": {
            "@type": "Organization",
            "name": `${jobDetails.companyName}`,
            "logo": jobDetails.logo
        },
        "jobLocation": {
            "@type": "Place",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": `${jobDetails.location || "Not given"}`,
                "addressRegion": `${jobDetails.state || "N/A"}`,
                "addressCountry": "GB"
            }
        },
        "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "GBP",
            "value": {
                "@type": "QuantitativeValue",
                "value": jobDetails.salary,
                "unitText": jobDetails?.salaryRate
            }
        },
        "url": window.location.href
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(jobPosting);
    document.head.appendChild(script);
}

export const gradbayJobDetailsFormat = (vacancy) => {

    let employmentType = "Full-Time";

    if (vacancy.employmentType === "CONTRACT") {
        employmentType = "CONTRACTOR";
    } else {
        employmentType = vacancy.employmentType
    }

    let videos = [];

    if (!_.isEmpty(vacancy.customTextBlock2)) {
        videos = vacancy.customTextBlock2.split(", ");
    }

    const media = [];

    if (videos.length) {
        media.push(...videos.map(video => ({
            type: "video",
            url: `${window.globalVars.bucket}${window.globalVars.bucketBusinessJobVideosDir}${vacancy.clientContact.id}/${video}`,
        })));
    }

    if (!_.isEmpty(vacancy.customText12)) {
        let customText = vacancy.customText12.trim();
        let images;

        if (customText.trim() === "") {
            images = [];
        } else {
            try {
                images = JSON.parse(customText.trim().startsWith("[") ? customText.trim() : `["${customText.trim()}"]`);
            } catch (error) {
                console.error('JSON parsing error:', error);
                console.error('Faulty JSON:', customText);
                images = [];
            }
        }

        media.push(...images.map(image => ({
            type: "image",
            url: `${window.globalVars.bucket}${window.globalVars.bucketBusinessJobPhotosDir}${vacancy.clientContact.id}/${image}`,
        })));
    }

    const jobDetails = {
        id: vacancy.id,
        title: vacancy.title,
        logo: vacancy.clientContact.customText14 ? `https://s3.eu-west-2.amazonaws.com/gradbay/business/profile-pictures/${vacancy.clientContact.id}/${vacancy.clientContact.customText14}` : null,
        salary: (vacancy.customInt3 ? `£${vacancy?.customInt3?.toLocaleString()} - £${vacancy?.salary?.toLocaleString()}` : `£${vacancy?.salary?.toLocaleString()}`),
        salaryRate: vacancy.salaryUnit,
        subTitle: vacancy.customText4,
        location: vacancy.address.city ? vacancy.address.city : null,
        companyDescription: vacancy?.clientCorporation?.companyDescription,
        jobDescription: vacancy.publicDescription,
        benefits: vacancy.customTextBlock1,
        tags: vacancy.customTextBlock4,
        media: media,
        employmentType: employmentType,
        url: vacancy.url,
        applicationSettings: vacancy.applicationSettings,
        clientCorporation: vacancy.clientCorporation,
        clientContact: vacancy.clientContact,
    }
    return jobDetails
}

export const setGradbayApplicationJSONLD = (vacancy) => {
    if (window.location.href.indexOf("gradbay.com") === -1) return

    let employmentType = "Full-Time";

    if (vacancy.employmentType === "CONTRACT") {
        employmentType = "CONTRACTOR";
    } else {
        employmentType = vacancy.employmentType
    }

    const existingScript = document.querySelector('script[type="application/ld+json"]');
    if (existingScript) return
    let datePostedStr = moment(vacancy.dateposted).format("YYYY-MM-DD");
    let validTillStr = moment(vacancy.expirationdate).format("YYYY-MM-DD");
    const jobPosting = {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        "title": `${vacancy.title}`,
        "description": `${vacancy.publicDescription}`,
        "datePosted": `${datePostedStr}`,
        "validThrough": `${validTillStr}`,
        "employmentType": `${employmentType}`,
        "hiringOrganization": {
            "@type": "Organization",
            "name": `${vacancy.clientCorporation.name}`,
            "logo": vacancy?.clientContact?.customText14 ? `https://s3.eu-west-2.amazonaws.com/gradbay/business/profile-pictures/${vacancy.clientContact.id}/${vacancy.clientContact.customText14}` : 'https://gradbay.s3.eu-west-2.amazonaws.com/small-logo.png'
        },
        "jobLocation": {
            "@type": "Place",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": `${vacancy.address?.address1 ? vacancy.address.address1 : "Not given"}`,
                "addressLocality": `${vacancy.address?.city ? vacancy.address.city : "Not given"}`,
                "addressRegion": `${vacancy.address?.countryCode ? vacancy.address.countryCode : "Not given"}`,
                "postalCode": `${vacancy.address?.zip ? vacancy?.address?.zip : "Not given"}`,
                "addressCountry": "GB"
            }
        },
        "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "GBP",
            "value": {
                "@type": "QuantitativeValue",
                "value": (vacancy.customInt3 ? `£${vacancy.customInt3} - £${vacancy.salary}` : vacancy.salary),
                "unitText": vacancy.salaryUnit
            }
        },
        "url": window.location.href
    };
    if (validTillStr === "Invalid date") {
        delete jobPosting.validThrough;
    }
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(jobPosting);
    document.head.appendChild(script);
}

export const redirectUserAccordingType = (type) => {
    switch (type) {
        case 2:
            window.location.href = "admin";
            break;
        case 5:
            window.location.href = "admin-marketing";
            break;
        case 6:
            window.location.href = "admin-agent";
            break;
        case 7:
            window.location.href = "admin-sonic-job";
            break;
        default:
            break;
    }
}

export const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
};

export const chartColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#4BC0C0', '#B33771', '#1B1464', '#009432', '#ED4C67', '#F79F1F', '#FF5733', '#33FF57']